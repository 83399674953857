import React from "react";
import ExpensesReport from "./ExpensesReport";
import StudentLocation from "./StudentLocations";
import SalesReport from "./SalesReport";
import PackageReport from "./PackageReport";

export default function Reports() {
  return (
    <>
      <ExpensesReport />
      <StudentLocation />
      <SalesReport />
      <PackageReport />
    </>
  );
}
