import React, { useEffect, useState } from "react";
import Input from "../components/Input/Input";
import { toast } from "react-toastify";
import axios from "axios";
import Table from "../components/table/Table";
import moment from "moment";
import Modal from "react-modal";

export default function AddExpenses() {
  const [itemCategory, setItemCategory] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemQty, setItemQty] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [updateExpenseData, setUpdateExpenseData] = useState(null);

  const [allExpenses, setAllExpenses] = useState([]);


   // Update Expense
   const [updateitemCategory, setUpdateCategory] = useState("");
   const [updateitemNumber, setUpdateNumber] = useState("");
   const [updateitemName, setUpdateName] = useState("");
   const [updateitemDescription, setUpdateDescription] = useState("");
   const [updateitemPrice, setUpdatePrice] = useState("");
   const [updateitemQty, setUpdateQty] = useState("");
   const [updateitemDate, setUpdateDate] = useState("");
   const [updateModal, setUpdateModal] = useState("");

  const handleSubmit = (e) => {
    let data = {
      itemCategory: itemCategory,
      itemNumber: itemNumber,
      itemName: itemName,
      itemDescription: itemDescription,
      itemPrice: itemPrice,
      itemDate: moment(createDate).format("YYYY-MM-DD"),
      itemQty: itemQty,
    };

    if (itemCategory == "" || itemNumber == ""  || itemName == "" || itemDescription == "" || itemPrice =="" || itemQty=="") {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {


    axios
      .post("https://kidultsports.herokuapp.com/api/app/expenses/send", data)
      .then((res) => {
        toast.info("Expense Added Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        toast.error("Expenses Not Added");
      });
    };
  };

  const getExpenses = async () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/expenses/all")
      .then((res) => {
        setAllExpenses(res.data);
      });
  };

  const updateExpense = (expenseid) => {
    axios    
     .post(`https://kidultsports.herokuapp.com/api/app/expenses/update/${expenseid}`,
      {
        itemCategory: updateitemCategory || updateExpenseData.itemCategory,
        itemNumber: updateitemNumber || updateExpenseData.itemNumber,
        itemDate: updateitemDate || updateExpenseData.itemDate,
        itemName: updateitemName || updateExpenseData.itemName,
        itemDescription: updateitemDescription || updateExpenseData.itemDescription,
        itemPrice: updateitemPrice || updateExpenseData.itemPrice,
        itemQty: updateitemQty || updateExpenseData.itemQty,
      },
    )
    .then((updatedData) => {
      setUpdateModal(false);
      toast.info("Expense updated successfully", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    });
  
  };

  const deleteItem = (id) => {
    axios
      .delete(
        `https://kidultsports.herokuapp.com/api/app/expenses/delete/${id}`
      )
      .then((res) => {
        toast.info("Expense Deleted Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        toast.error("Internal Server Error, Please Try Again");
      });
  };

  useEffect(() => {
    getExpenses();
  }, []);

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderBody = (item, index) => {
    return (
      <tr onClick={() => {}} style={{ cursor: "pointer" }} key={index}>
        <>
          <td>{moment(item.itemDate).format("MM/DD/YYYY")}</td>
          <td>{item.itemNumber}</td>
          <td>{item.itemCategory}</td>
          <td>{item.itemName}</td>
          <td>{item.itemDescription}</td>
          <td>{item.itemPrice}</td>
          <td>{item.itemQty}</td>
          <td 
            onClick={() => {
              setUpdateExpenseData(item);
              setUpdateModal(true);
            }}
            style={{
              color: "red",
            }}
          >
            Update Item
          </td>
          <td
            onClick={() => {
              deleteItem(item._id);
            }}
            style={{
              color: "red",
            }}
          >
            刪除Delete Item
          </td>
        </>
      </tr>
    );
  };

  return (
    <>
      <h2 className="page-header">Add Expenses</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Input
          value={itemCategory}
          onChange={(e) => setItemCategory(e.target.value)}
          placeholder="Item Category"
          icon="category"
        />
        <Input
          value={itemNumber}
          onChange={(e) => setItemNumber(e.target.value)}
          placeholder="Item No."
          icon="book"
        />
        <Input
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          placeholder="Item Name"
          icon="edit-alt"
        />
        <Input
          value={itemDescription}
          onChange={(e) => setItemDescription(e.target.value)}
          placeholder="Item Description"
          icon="notepad"
        />
        <Input
          value={itemPrice}
          onChange={(e) => setItemPrice(e.target.value)}
          placeholder="Item Price"
          icon="dollar"
        />
        <Input
          value={itemQty}
          onChange={(e) => setItemQty(e.target.value)}
          placeholder="Item Qty"
          package
          icon="package"
        />
        <Input
          value={createDate}
          onChange={(e) => setCreateDate(e.target.value)}
          placeholder="Create Date"
          type="date"
          icon="calendar"
        />
        <div onClick={handleSubmit} className="add_button">
          <p>Add Expenses</p>
          <i className={`bx bx-plus`}></i>
        </div>
      </div>

      {allExpenses.length > 0 ? (
        <div className="card">
          <div className="card__body">
            <Table
              limit="20"
              headData={[
                "Date",
                "Number",
                "Category",
                "Name",
                "Description",
                "Price",
                "Quantity",
                "Update",
                "Delete"
              ]}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={allExpenses}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </div>
        </div>
      ) : null}

{updateExpenseData ? (
        <Modal
          isOpen={updateModal}
          onRequestClose={() => setUpdateModal(false)}
          contentLabel="Update Expense"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              width: "25%",
              bottom: "auto",
              backgroundColor: "#FAFAFB",
              border: "none",
              overflow: "scroll",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              height: "700px"
            },
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <h2>Update Expense</h2>
          <div className="modal-container" style={{
                marginTop: "10px"
              }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
                <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Item id
              </p>
              <input
                className="modal-input"
                defaultValue={updateExpenseData._id}
            
              />
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Item Category
              </p>
              <input
                className="modal-input"
                defaultValue={updateExpenseData.itemCategory}
                onChange={(e) => setUpdateCategory(e.target.value)}
              />
              <p style={{ marginTop: "10px", fontSize: "13px" }}>
                {" "}
               Number
              </p>
              <input
                className="modal-input"
                defaultValue={updateExpenseData.itemNumber}
                onChange={(e) => setUpdateNumber(e.target.value)}
              />
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Name
              </p>
              <input
                className="modal-input"
                defaultValue={updateExpenseData.itemName}
                onChange={(e) => setUpdateName(e.target.value)}
              />
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Description
              </p>
              <input
                className="modal-input"
                defaultValue={updateExpenseData.itemDescription}
                onChange={(e) => setUpdateDescription(e.target.value)}
              />
               <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Price
              </p>
              <input
                className="modal-input"
                defaultValue={updateExpenseData.itemPrice}
                onChange={(e) => setUpdatePrice(e.target.value)}
              />

              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Qty
              </p>
              <input
                className="modal-input"
                defaultValue={updateExpenseData.itemQty}
                onChange={(e) => setUpdateQty(e.target.value)}
              />

              <div className="buttonContainer">
                <div
                  onClick={() => {
                    let id = updateExpenseData._id;
                    axios
                      .delete(
                        `https://kidultsports.herokuapp.com/api/app/expenses/delete/${id}`
                      )
                      .then((res) => {
                        window.location.reload();
                      });
                  }}
                  style={{
                    color: "red",
                    display: "flex",
                    backgroundColor: "darkred",
                    color: "white",
                    padding: "10px"
                  }}
                >
                  <p>Delete</p>
                  <i className={`bx bx-user-x`}></i>
                </div>
                
           <div
                  onClick={() => updateExpense(updateExpenseData._id)}
      
                  style={{
                    color: "red",
                    display: "flex",
                    backgroundColor: "#3895D3",
                    color: "white",
                    padding: "10px",
                    marginLeft: "5px"
                  }}
                >
                  <p>Update User</p>
                  <i className={`bx bx-user`}></i>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

    </>
  );
}
