import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function ExpensesReport() {
  const [data, setData] = useState([]);
  const [year, setYear] = useState();

  const mapDataToTable = () => {
    const AllMOnths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const getCategoriesFromMonth = (month) => {
      let categories = [];
      let monthData = data[month];
      if (monthData) {
        Object.keys(monthData).forEach((category) => {
          categories.push(category);
        });
      }
      return categories;
    };

    const getAllCategory = () => {
      let categories = [];
      AllMOnths.forEach((month) => {
        let AllMonths = getCategoriesFromMonth(month);
        if (AllMonths.length > 0) {
          AllMonths.forEach((category) => {
            if (!categories.includes(category)) {
              categories.push(category);
            }
          });
        }
      });

      return categories;
    };

    const getExpensesPrice = (category, month) => {
      var totalExpenses = 0;

      let monthData = data[month];
      if (monthData) {
        // Check if the category exists in the month
        if (monthData[category]) {
          monthData[category].expenses.forEach((expense) => {
            totalExpenses +=
              Number(expense.itemPrice) * Number(expense.itemQty);
          });
        }
      }
      return totalExpenses;
    };

    const getTotalExpenses = (category) => {
      // Get total expenses for a category from all months
      var totalExpenses = 0;

      AllMOnths.forEach((month) => {
        let monthData = data[month];
        if (monthData) {
          if (monthData[category]) {
            monthData[category].expenses.forEach((expense) => {
              totalExpenses +=
                Number(expense.itemPrice) * Number(expense.itemQty);
            });
          }
        }
      });

      return totalExpenses;
    };

    let AllCateogies = getAllCategory();

    return (
      <div
        style={{
          marginTop: "30px",
        }}
        className="card"
      >
        <div className="card__body">
          <table id="table-to-xls">
            <thead>
              <tr>
                <th></th>
                {AllMOnths.map((item) => {
                  return <th>{item}{year}</th>;
                })}
                <th></th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {AllCateogies.map((category) => {
                return (
                  <tr>
                    <td>{category}</td>
                    {AllMOnths.map((item) => {
                      return <td>{getExpensesPrice(category, item)}</td>;
                    })}
                    <td></td>
                    <td>{getTotalExpenses(category)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const getData = () => {
    let sortYear = localStorage.getItem("year");
    setYear(sortYear);
    let url = `https://kidultsports.herokuapp.com/api/app/expenses/report/${sortYear}`;

    axios
      .get(url)

      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const years = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031,
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>Expenses Report</h2>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename="ExepensesReport"
            sheet="ExpensesReport"
            buttonText="Download as XLS"
          />
        </div>
        <select
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            localStorage.setItem("year", e.target.value);
            window.location.reload();
          }}
          style={{
            width: "100px",
          }}
          className="modal-input"
        >
          {years.map((item) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
      </div>
      {mapDataToTable()}
    </div>
  );
}
