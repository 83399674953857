import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import moment from "moment";
import Input from "../components/Input/Input";

export default function RenderBodyEnrollStudent(item, fromdrop, studentsData, coursesData) {
  const [studentData, setStudentData] = useState(null);
  const [currentCourseData, setCurrentCourseData] = useState(null);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [classes, setClasses] = useState([]);
  const [oldStudentId, setOldStudentId] = useState(item?.item.studentId || fromdrop.studentId);
  const [payment, setPayment] = useState(item?.item.paymentMethod || "");
  const [invoiceId, setInvoiceId] = useState(item?.item?.Ids?.invoiceId || "");
  const [FPSId, setFPSId] = useState("");
  const [isPaid, setIsPaid] = useState(item.item?.payed || false);

  const [students, setStudents] = useState([]);
  const [studentId, setStudentId] = useState(item?.item.studentId || fromdrop.studentId);
  const [courseId, setCourseId] = useState(item?.item?.Ids?.courseId || "");
  const [allCourses, setAllCourses] = useState([]);
  const [updateEnrollmentData, setUpdateEnrollmentData] = useState(null);

  const [updateStudent, setUpdateStudent] = useState("");
  const [updateCourse, setUpdateCourse] = useState("");
  const [updatePayment, setUpdatePayment] = useState("");
  const [updateModal, setUpdateModal] = useState("");

  useEffect(async() => {
      //For update page student dropdown
    let allStudentsTempArray = await axios.get("https://kidultsports.herokuapp.com/api/auth/users");
    
    let studentsArray = [];
    if(allStudentsTempArray.data.length > 0){
      for (let i = 0; i < allStudentsTempArray.data.length; i++) {
        if (
          allStudentsTempArray.data[i].role !== "tutor" &&
          allStudentsTempArray.data[i].role !== "admin" &&
          allStudentsTempArray.data[i].role !== "parent"
        ) {
          studentsArray.push(allStudentsTempArray.data[i]);
        }
      }
     
      setStudents(studentsArray);
    }
  },[]);

  useEffect(async() => {    
      //For update page courses dropdown
    let coursesTempArray = await axios.get("https://kidultsports.herokuapp.com/api/app/courses");
    if(coursesTempArray.data.length > 0){
      setAllCourses(coursesTempArray.data);
    }    
    let currentCourse = coursesTempArray.data.filter(obj => obj.classes.includes(item?.item?.Ids?.courseId))[0];
    setCurrentCourseData(currentCourse);
  
  },[]);

  const getClasses = async (id) => {
    setCourseId(id);
    if (id.length > 15) {
      const response = await fetch(
        `https://kidultsports.herokuapp.com/api/app/course/${id}`
      );
      const data = await response.json();
      setClasses(data.classes);
      setShowCheckBox(true);
    }
  };

  const updateEnrollment = async (studentId,courseId,invoiceId,payment,isPaid) => {
    let postData = {
      studentId: studentId,
      courseId: courseId
    };

    let updateInvoice = await axios.put(
      `https://kidultsports.herokuapp.com/api/app/invoice/update/${invoiceId}`,
      postData
    )
    
    if(updateInvoice){
      //Only need to update course payment information
      let courseInfo = await axios.get(`https://kidultsports.herokuapp.com/api/app/course/${courseId}`)
      if(courseInfo){
          let newEnrollment = await axios.post(
            "https://kidultsports.herokuapp.com/api/app/course/enroll/" +
            courseId,
            {
              student: {
                date: moment().format("YYYY-MM-DD hh:mm"),
                userId: studentId,
                paymentMethod: payment,
                FPSID: FPSId,
                invoice: invoiceId,
                checkedIn: false,
                payed: isPaid,
              },
              oldStudentId: oldStudentId
            }
          )
  
          if(newEnrollment){
            setUpdateModal(false);
  
            toast.info("Enrollment Updated Successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
      }
    }
    
  };


  return (
    <>
    <tr onClick={() => {}} style={{ cursor: "pointer" }} key={item}>
    <td>{item?.item?.studentId}</td>
      <td>{item?.item?.studentInfo?.FirstName}{" "}{item?.item?.studentInfo?.LastName}</td>

      <td>{item?.item?.courseData?.courseName}</td>
      <td>{item?.item?.courseData?.courseDescription}</td>
      <td>${item.item?.courseData?.coursePrice}</td>
      <td>
        {item?.item?.paymentMethod}
        {item?.item?.paymentMethod == "fps" ? ` ${item?.FPSID} ` : null}
      </td>
      <td>{item.item?.payed == true ? "Payed" : "Not Paid"}</td>

      <td 
        onClick={(item) => {
          setUpdateEnrollmentData(item);
          setUpdateModal(true);
        }}
        style={{
          color: "red",
        }}
      >
        Update Enrollment
      </td>
      <td
        onClick={() => {
          window.open(
            `https://kidultsports.herokuapp.com/api/app/invoice/generatePDF/${item.item.Ids.invoiceId}/${item.item.Ids.studentId}/${item.item.Ids.courseId}
            `
          );
        }}
      >
        {!item?.item?.paymentMethod ? null : "Download Invoice"}
      </td>
     
    </tr>

    {updateEnrollmentData ? (
        <Modal
          isOpen={updateModal}
          onRequestClose={() => setUpdateModal(false)}
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              width: "30%",
              bottom: "auto",
              backgroundColor: "#FAFAFB",
              border: "none",
              overflow: "visible",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div className="modal-container">
            <h2>Update Enrollment</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px"
              }}
            >
              <select
                value={studentId}
                onChange={(e) => setStudentId(e.target.value)}
                className="form-control"
              >
                <option>Select Student *</option>

                {students.map((data, key) => (
                  <option value={data._id} key={key}>
                    {data.FirstName}
                  </option>
                ))}
              </select>

              <select
                value={currentCourseData?._id || courseId}
                onChange={(e) => getClasses(e.target.value)}
                className="form-control"
              >
                <option>Select Course *</option>

                {allCourses.map((data, key) => (
                  <option value={data._id} key={key}>
                    {data.courseData.courseName}
                  </option>
                ))}
              </select>
              <select
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                className="form-control"
              >
                <option>Select Payment Method *</option>
                <option value="cash">Cash</option>
                <option value="stripe">Stripe</option>
                <option value="fps">FPS</option>
              </select>
              {payment == "fps" ? (
                <Input
                  value={FPSId}
                  onChange={(e) => {
                    setFPSId(e.target.value);
                  }}
                  placeholder={"FPS ID"}
                  icon="money"
                />
              ) : null}
              <div className="form-control">
                <input 
                  type="checkbox" 
                  checked={isPaid}
                  id="isPaid" 
                  onClick={(e) => {
                    setIsPaid(!isPaid)
                  }}/>
                <label for="isPaid" style={{marginLeft: "10px"}}>Payed</label>
              </div>
           

              <div className="buttonContainer" style={{marginTop: "10px"}}> 
                <div
                  onClick={() => updateEnrollment(studentId,currentCourseData?._id || courseId,invoiceId,payment,isPaid)}
               
                  style={{
                    backgroundColor: "#3895D3",
                    color: "white",
                    display: "flex",
                    padding: "8px"
                  }}
                >
                  <p>Update Enrollment</p>
                  <i className={`bx bx-user`}></i>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
    
  );
}
