import React, { useState } from "react";

import useTable from "./useTable";
import styles from "./Table.module.css";
import TableFooter from "./TableFooter";
import RenderBodyEnrollStudent from "../../pages/renderBodyEnrollStudent";

const Table = ({ data, rowsPerPage}) => {
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);
  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableRowHeader}>
          <tr>
          <th className={styles.tableHeader}>Student ID</th>
            <th className={styles.tableHeader}>Student Name</th>
            <th className={styles.tableHeader}>Course Name</th>
            <th className={styles.tableHeader}>Course Description</th>
            <th className={styles.tableHeader}>Course Price</th>
            <th className={styles.tableHeader}>Payment Method</th>
            <th className={styles.tableHeader}>Invoice</th>
            <th className={styles.tableHeader}>Payed</th>
            <th className={styles.tableHeader}>Update</th>
          </tr>
        </thead>
        <tbody>
          {slice.map((el) => (
            <RenderBodyEnrollStudent key={el._id} item={el}/>
          ))}
        </tbody>
      </table>
      <TableFooter range={range} slice={slice} setPage={setPage} page={page}/>
    </>
  );
};

export default Table;
