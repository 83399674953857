import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Table from "../components/table/Table";
import Input from "../components/Input/Input";
import axios from "axios";
import Modal from "react-modal";
import "../assets/css/AllStudent.css";
import { OutlinedInput } from "@material-ui/core";

const Customers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentModal, setStudentModal] = useState(false);
  const [userData, setuserData] = useState({});
  const [studentloading, setStudentLoading] = useState(true);

  // Update User State
  const [Id, setId] = useState("");
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [AbsentDate, setAbsentDate] = useState("");
  const [MakeUpDate, setMakeUpDate] = useState("");
  const [Reason, setReason] = useState("");
  const [MakeUpRemark, setMakeUpRemark] = useState("");
  const [updateModal, setUpdateModal] = useState("");
  const [updateMakeUpData, setUpdateMakeUpData] = useState(null);
  
  let theme = localStorage.getItem("themeMode");

  const customerTableHead = [
    "Index",
    "First Name",
    "Email Address",
    "Phone Number",
    "Role",
    "Absent Date",
    "Make-up Date",
    "Reason",
    "Admin Remark",
    "Update",
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "25%",
      bottom: "auto",
      backgroundColor: theme === "theme-mode-dark" ? "#202020" : "#FAFAFB",
      border: "none",
      overflow: "visible",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,

      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };
  const updateCourse = async (e) => {
    let Id = updateMakeUpData._id;
    let postData = {
      Name: Name || updateMakeUpData.Name,
      Phone: Phone || updateMakeUpData.Phone,
      AbsentDate: AbsentDate || updateMakeUpData.AbsentDate,
      MakeUpDate: MakeUpDate || updateMakeUpData.MakeUpDate,
      Email: Email || updateMakeUpData.Email,
      Reason: Reason || updateMakeUpData.Reason,
      MakeUpRemark: MakeUpRemark,
    };

    let updateMakeUp = await axios.put(
      `https://kidultsports.herokuapp.com/api/app/makeup-class/update/${Id}`,
      postData
    )
    
    if(updateMakeUp){
      //Only need to update course payment information
            setUpdateModal(false);
  
            toast.info("MakeUp Remark Updated Successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
      };
    

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderBody = (item, index) => {
    console.log();

    return (
      <tr key={index}>
        <>
          <td>{Number(index) + 1}</td>
          <td>{item.Name}</td>
          <td>{item.Email}</td>
          <td>{item.Phone}</td>
          <td
            style={{
              textTransform: "capitalize",
            }}
          >
            {item.role || "Student"}
          </td>
          <td>{item.AbsentDate}</td>
          <td>{item.MakeUpDate}</td>
          <td>{item.Reason}</td>
          <td>{item.MakeUpRemark}</td>
          <td 
           onClick={() => {
          setUpdateMakeUpData(item);
          setUpdateModal(true);
        }}
        style={{
          color: "red",
        }}
      >
        Update MakeUp
      </td>

      {updateMakeUpData ? (        
    <Modal
      isOpen={updateModal}
      onRequestClose={() => setUpdateModal(false)}
      ariaHideApp={false}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          width: "30%",
          bottom: "auto",
          backgroundColor: "#FAFAFB",
          border: "none",
          overflow: "visible",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
  
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      }}
    >
      <div className="modal-container">
        <h2>Update MakeUp</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >        
                 <p> ID </p>
              <input
                 value={updateMakeUpData._id}
                placeholder="ID"
                className="modal-input"
                defaultValue={updateMakeUpData._id} 
                onChange={(e) => {
                  setId(e.target.value);
                }}
              />   
              <p> First Name </p>
              <input
                 value={updateMakeUpData.Name}
                name="Name"
                placeholder="First Name"
                className="modal-input"
                defaultValue={updateMakeUpData.Name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <p
                style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
              >
                Email
              </p>
              <input
               value={updateMakeUpData.Email}
               name="Email"
                placeholder="Email Address"
                className="modal-input"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                defaultValue={updateMakeUpData.Email}
              />
                  <p  style={{
                  marginTop: "5px",
                  fontSize: "11px",  }}  > 
              Phone  </p>
              <input
               value={updateMakeUpData.Phone}
               name="Phone"
                placeholder="Phone"
                className="modal-input"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                defaultValue={updateMakeUpData.Phone}
              />
                     <p  style={{
                  marginTop: "5px",
                  fontSize: "11px",  }}  > 
              Absent Date  </p>
              <input
               name="AbsentDate"
                placeholder="AbsentDate"
                className="modal-input"
                onChange={(e) => {
                  setAbsentDate(e.target.value);
                }}
                defaultValue={updateMakeUpData.AbsentDate}
              />
                   <p  style={{
                  marginTop: "5px",
                  fontSize: "11px",  }}  > 
              MakeUp Date  </p>
              <input
              name="MakeUpDate"
                placeholder="MakeUp Date"
                className="modal-input"
                onChange={(e) => {
                  setMakeUpDate(e.target.value);
                }}
                defaultValue={updateMakeUpData.MakeUpDate}
              />
                   <p  style={{
                  marginTop: "5px",
                  fontSize: "11px",  }}  > 
              Reason </p>
              <input
              name="Reason"
                placeholder="Reason"
                className="modal-input"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                defaultValue={updateMakeUpData.Reason}
              />
              Makeup Remarks
              <textarea 
              name="MakeUpRemark" 
              placeholder="MakeUp Remarks"
              className="modal-input"
              onChange={(e) => {
              setMakeUpRemark(e.target.value);
            }}
            defaultValue={updateMakeUpData.MakeUpRemark}

          />
           <div className="buttonContainer" style={{marginTop: "10px"}}> 
                <div
                    onClick={() => {
                      updateCourse(updateMakeUpData._id);
                    }}
                    className="add_button">
                  <p>Update MakeUp</p>
                  <i className={`bx bx-user`}></i>
                </div>
              </div>
              
        </div>
      </div>
    </Modal>
  ) : null}

        </>
      </tr>


    );
  };

 
  useEffect(() => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/makeup-class/get")
      .then((e) => {
        setAllUsers(e.data);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <h2 className="page-header">Make-up Class (Create from Kidultsports Student App Make-Up Form)</h2>
      <div className="row">
        <div className="col-12">
          {!loading ? (
            <div className="card">
              <div className="card__body">
                <Table
                  limit="20"
                  headData={customerTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={allUsers}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Customers;
