import React from "react";
import "./Input.css";
export default function Input(props) {
  return (
    <div className={`input_container ${props.containerStyle}`}>
      <input
        type={props.type || "text"}
        placeholder={props.placeholder}
        {...props}
      />

      <i className={`bx bx-${props.icon}`}></i>
    </div>
  );
}
