import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

export default function ExpensesReport() {
  const [data, setData] = useState([]);
  const [getAllCourse, setAllCourses] = useState([]);
  const [year, setYear] = useState();
  const mapDataToTable = () => {
    const AllMOnths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const getCategoriesFromMonth = (month) => {
      let categories = [];
      let monthData = data[month];
      if (monthData) {
        Object.keys(monthData).forEach((category) => {
          categories.push(category);
        });
      }
      return categories;
    };

    const getAllCategory = () => {
      let categories = [];
      AllMOnths.forEach((month) => {
        let AllMonths = getCategoriesFromMonth(month);
        if (AllMonths.length > 0) {
          AllMonths.forEach((category) => {
            if (!categories.includes(category)) {
              categories.push(category);
            }
          });
        }
      });

      return categories;
    };

    const getSalesPrice = (category, month) => {

      let monthData = data[month];
      if (monthData) {
        // Check if the category exists in the month
        if (monthData[category]) {
          return monthData[category].totalPrice;
        }
      }
    };

    const getTotalPrice = (category) => {
      let totalPrice = 0;
      AllMOnths.forEach((month) =>   {
        let monthData = data[month];
        if (monthData) {
          // Check if the category exists in the month
          if (monthData[category]) {
            totalPrice += monthData[category].totalPrice;
          }
        }
      });
      return totalPrice;
    };

    let AllCateogies = getAllCategory();

    return (
      <div
        style={{
          marginTop: "30px",
        }}
        className="card"
      >
        <div className="card__body">
          <table id="packageReport">
            <thead>
              <tr>
                <th></th>
                {AllMOnths.map((item) => {
                  return <th>{item}{year}</th>;
                })}
                <th></th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {AllCateogies.map((category) => {
                return (
                  <tr>
                    <td>{category}</td>
                    {AllMOnths.map((item) => {
                      return <td>{getSalesPrice(category, item)}</td>;
                    })}
                    <td></td>
                    <td>{getTotalPrice(category)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const getData = () => {
    let sortYear = localStorage.getItem("year");
    setYear(sortYear);
    let url = `https://kidultsports.herokuapp.com/api/app/package/report/${sortYear}`;
    
    axios
      .get(url)
      .then((res) => {
        setData(res.data);
      });
  };

 
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <h1>Package Report</h1>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="packageReport"
          filename="packageReport"
          sheet="packageReport"
          buttonText="Download as XLS"
        />
      </div>
      {mapDataToTable()}
    </div>
  );
}
