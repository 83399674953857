import axios from "axios";
import React, { useEffect, useState } from "react";

export default function RenderBody({ data }) {
  const [StudentData, setStudentData] = useState(null);
  useEffect(() => {
    
    axios
      .get(`https://kidultsports.herokuapp.com/api/auth/user/${data.studentId}`)
      .then((e) => {
        setStudentData(e.data);
      });
  }, [StudentData]);

  return (
    <tr onClick={() => {}} style={{ cursor: "pointer" }}>
      <td>{StudentData ? StudentData.FirstName : ""}</td>
      <td>{data?.classData?.classData?.className}</td>
      <td>{data?.classData?.classData?.classDescription}</td>
      
      <td>{data.classData?.classData?.classDate}</td>
    </tr>
  );
}
