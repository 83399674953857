import React from "react";

import { Route, Switch } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import AllStudents from "../pages/AllStudents";
import AddStudent from "../pages/AddStudent";
import SendNotice from "../pages/SendNotice";
import AddTutor from "../pages/AddTutor";
import AddCourse from "../pages/AddCourse";
import AddClass from "../pages/AddClass";
import Login from "../pages/Login";
import EnrollStudent from "../pages/EnrollStudent";
import AddVideo from "../pages/AddVideo";
import AddNews from "../pages/AddNews";
import MakeupClass from "../pages/MakeupClass";
import DropClass from "../pages/DropClass";
import AddExpenses from "../pages/AddExpenses";
import generateInvoice from "../pages/generateInvoice";
import AddLocation from "../pages/AddLocation";
import Reports from "../pages/Reports";
const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/students" component={AllStudents} />
      <Route path="/add-student" component={AddStudent} />
      <Route path="/send-notice" component={SendNotice} />
      <Route path="/add-tutor" component={AddTutor} />
      <Route path="/add-course" component={AddCourse} />
      <Route path="/add-class" component={AddClass} />
      <Route path="/add-location" component={AddLocation} />
      <Route path="/login" component={Login} />
      <Route path="/add-video" component={AddVideo} />
      <Route path="/enroll-student" component={EnrollStudent} />
      <Route path="/add-news" component={AddNews} />
      <Route path="/add-make-up-class" component={MakeupClass} />
      <Route path="/drop-class" component={DropClass} />
      <Route path="/add-expenses" component={AddExpenses} />
      <Route path="/generate-invoice" component={generateInvoice} />
      <Route path="/reports" component={Reports} />
    </Switch>
  );
};

export default Routes;
