import React, { useState, useEffect } from "react";
import { ScrollView } from '@react-ui-org/react-ui';
import { toast } from "react-toastify";
import Table from "../components/table/Table";
import axios from "axios";
import Modal from "react-modal";
import moment from "moment";
import "../assets/css/AllStudent.css";
import { OutlinedInput } from "@material-ui/core";
import { Widget } from "@uploadcare/react-widget";

const Customers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentModal, setStudentModal] = useState(false);
  const [userData, setuserData] = useState({});
  const [studentloading, setStudentLoading] = useState(true);

  // Update User State
  const [studentFirstName, setStudentFirstName] = useState("");
  const [studentLastName, setStudentLastName] = useState("");
  const [studentDateofBirth, setStudentDateofBirth] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentAddress, setStudentAddress] = useState("");
  const [studentPhone, setStudentPhone] = useState("");
  const [studentSource, setStudentSource] = useState("");
  const [studentImage, setStudentImage] = useState("");
  const [studentRole, setStudentRole] = useState("");
  const [studentPassword, setStudentPassword] = useState("");
  const [StudentNickName, setNickName] = useState("");

  let theme = localStorage.getItem("themeMode");

  const customerTableHead = [
    "Index",
    "First Name",
    "Last Name",
    "Nick Name",
    "Email Address",
    "Phone Number",
    "Role",
    "Create Date",
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "25%",
      bottom: "auto",
      backgroundColor: "#FAFAFB",
      border: "none",
      marginRight: "-50%",
      height: "90%",
      overflowX: "hidden",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;
  const deleteAccount = (userId) => {
    axios
      .delete(`https://kidultsports.herokuapp.com/api/auth/delete/${userId}`)
      .then(() => {
        toast.info("User deleted successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };
  const updateUser = (userId) => {
    axios
      .get(`https://kidultsports.herokuapp.com/api/auth/user/${userId}`)
      .then((e) => {
        let studentData = e.data;
        axios
          .put(`https://kidultsports.herokuapp.com/api/auth/update/${userId}`, {
            Email: studentEmail || studentData.Email,
            FirstName: studentFirstName || studentData.FirstName,
            LastName: studentLastName || studentData.LastName,
            nickName: StudentNickName || studentData.nickName,
            Password: studentPassword || null,
            StreetAddressLine2: studentAddress || studentData.StreetAddressLine2,            
            Phone: studentPhone || studentData.Phone,
            DateOfBirth: studentDateofBirth || studentData.DateOfBirth,
            profile: studentImage || studentData.profile,
            source: studentSource || studentData.source,
            role: studentRole || studentData.role,
          })
          .then((updatedData) => {
            toast.info("User updated successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
      });
  };

  const renderBody = (item, index) => {
    return (
      <tr
        onClick={() => {
          setuserData(item);
          setStudentLoading(false);
          setStudentModal(true);
        }}
        style={{ cursor: "pointer" }}
        key={index}
      >
        <>
          <td>{item.index + 1}</td>
          <td>
            {item.role == "tutor" ? item.userData.tutorName : item.FirstName}
          </td>
          <td>
            {item.role == "tutor" ? item.userData.tutorName : item.LastName}
          </td>
          <td>{item.nickName || item["Nick Name 暱稱"]}</td>

          <td>{item.Email}</td>
          <td>
            {item.role == "tutor" ? item.userData.phoneNumber : item.Phone}
          </td>
          <td
            style={{
              textTransform: "capitalize",
            }}
          >
            {item.role || "Student"}
          </td>
          <td>{moment(item.updatedAt).format('YYYY-MM-DD') || "NA"}</td>
        </>
      </tr>
    );
  };

  useEffect(() => {
    axios.get("https://kidultsports.herokuapp.com/api/auth/users").then((e) => {
  
      let AllUsers = e.data;
      let StudentAndParent = [];
      AllUsers.map((e) => {
        if (e.role !== "tutor") {
          StudentAndParent.push(e);
        }
      });
      if (StudentAndParent.length > 0) {
        // Add index to each student
        let data = StudentAndParent.reverse();
        data.map((e, index) => {
          e.index = index;
        });
        let sortedBasedOnName = data.sort((a, b) => a.FirstName > b.FirstName ? 1 : -1);
        setAllUsers(data);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <h2 className="page-header"></h2>
      <div className="row">
        <div className="col-12">
          {!loading ? (
            <div className="card">
              <div className="card__body">
                <Table
                  limit="20"
                  headData={customerTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={allUsers}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              </div>
            </div>
          ) : null}
        </div>
        {!studentloading ? (
          <Modal
            isOpen={studentModal}
            onRequestClose={() => setStudentModal(false)}
            style={customStyles}
          >
            <div className="modal-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
              <img
                src={
                  userData.profile
                    ? userData.profile
                    : `https://eu.ui-avatars.com/api/?background=9CD5D5&color=fff&size=512&name=${userData.FirstName}+${userData.Lastman}`
                }
                className="modal-img"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  First Name
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setStudentFirstName(e.target.value)}
                  defaultValue={userData.FirstName}
                />
                 <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  Last Name
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setStudentLastName(e.target.value)}
                  defaultValue={userData.LastName}
                />
                 <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  Nick Name
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setNickName(e.target.value)}
                  defaultValue={userData.nickName}
                />
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  User Email
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setStudentEmail(e.target.value)}
                  defaultValue={userData.Email}
                />
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  User Phone
                </p>

                <input
                  className="modal-input"
                  onChange={(e) => setStudentPhone(e.target.value)}
                  defaultValue={userData.Phone}
                />
                 <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  Address
                </p>

                <input
                  className="modal-input"
                  onChange={(e) => setStudentAddress(e.target.value)}
                  defaultValue={userData.StreetAddressLine2}
                />
                 <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  Date Of Birth
                </p>

                <input
                  className="modal-input"
                  onChange={(e) => setStudentDateofBirth(e.target.value)}
                  defaultValue={userData.DateOfBirth}
                />
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  User Role
                </p>

                <select
                  defaultValue={userData.role}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                  }}
                  onChange={(e) => setStudentRole(e.target.value)}
                >
                  <option value="student">Student</option>
                  <option value="parent">Parent</option>
                  <option value="admin">Admin</option>
                </select>
                <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  User Password
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setStudentPassword(e.target.value)}
                  placeholder={"Password"}
                />
                   <p
                  style={{
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  How to know us
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setStudentSource(e.target.value)}
                  defaultValue={userData.source}
                />
              </div>
                <Widget
                  onChange={(info) => {
                    setStudentImage(info.cdnUrl);
                  }}
                  publicKey="96f3fcac48c20a4671a9"
                  clearable
                />
              </div>

              <br />
              <div className="buttonContainer">
                {/* <div
                  onClick={() => updateUser(userData._id)}
                  className="add_button center"
                >
                  <p>Update User</p>
                  <i className={`bx bx-pencil`}></i>
                </div> */}
                <div
                  onClick={() => deleteAccount(userData._id)}
                  className="remove_button center"
                >
                  <p>Delete User</p>
                  <i className={`bx bx-user-x`}></i>
                </div>
                <div
                  onClick={() => updateUser(userData._id)}
                  className="remove_button center"
                  style={{
                    backgroundColor: "#3895D3",
                  }}
                >
                  <p>Update User</p>
                  <i className={`bx bx-user`}></i>
                </div>
              </div>
            </div>
     
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

export default Customers;
