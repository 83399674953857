import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function ExpensesReport() {
  const [data, setData] = useState([]);
  const [year, setYear] = useState();

  const mapDataToTable = () => {
    const AllMOnths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const getCategoriesFromMonth = (month) => {
      let categories = [];
      let monthData = data[month];
      if (monthData) {
        Object.keys(monthData).forEach((category) => {
          categories.push(category);
        });
      }
      return categories;
    };

    const getAllCategory = () => {
      let categories = [];
      AllMOnths.forEach((month) => {
        let AllMonths = getCategoriesFromMonth(month);
        if (AllMonths.length > 0) {
          AllMonths.forEach((category) => {
            if (!categories.includes(category)) {
              categories.push(category);
            }
          });
        }
      });

      return categories;
    };

    const getAllExpensesFromCateogiryAndMonth = (category, month) => {
      console.log(category, month);

      let monthData = data[month];
      if (monthData) {
        // Check if the category exists in the month
        if (monthData[category]) {
          return monthData[category].studentLength;
        }
      }
    };

    let AllCateogies = getAllCategory();

    return (
      <div
        style={{
          marginTop: "30px",
        }}
        className="card"
      >
        <div className="card__body">
          <table id="studentTable">
            <thead>
              <tr>
                <th></th>
                {AllMOnths.map((item) => {
                  return <th>{item}{year}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {AllCateogies.map((category) => {
                return (
                  <tr>
                    <td>{category}</td>
                    {AllMOnths.map((item) => {
                      return (
                        <td>
                          {getAllExpensesFromCateogiryAndMonth(category, item)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const getData = () => {
    let sortYear = localStorage.getItem("year");
    setYear(sortYear);
    let url = `https://kidultsports.herokuapp.com/api/app/locations/report/${sortYear}`;
    
    axios
      .get(url)
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    getData();
  }, []);
 
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
            <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        <h1>Student Report</h1>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="studentTable"
          filename="StudentReport"
          sheet="StudentReport"
          buttonText="Download as XLS"
        />
        </div>
          
      </div>
      {mapDataToTable()}
    </div>
  );
}