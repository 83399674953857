import React, { useState, useEffect } from "react";
import Input from "../components/Input/Input";
import axios from "axios";
import { toast } from "react-toastify";
import Table from "../components/table/Table";
import RenderBody from "./renderBodyDrop";

export default function EnrollStudent() {
  const [courseId, setCourseId] = useState("");
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [studentId, setStudentId] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [removeClass, setRemoveClass] = useState("");
  const [AllDropStudents, setAllDropStudents] = useState([]);

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const getClasses = async (id) => {
    
    setCourseId(id);
    let AllClasses = [];

      axios
        .get(`https://kidultsports.herokuapp.com/api/app/course/${id}`)
        .then((res) => {
    
          res.data.classes.map((classId) => {
            axios
              .get(
                "https://kidultsports.herokuapp.com/api/app/class/" + classId
              )
              .then((res2) => {
                AllClasses.push(res2.data);
                setClasses(AllClasses);
    
              });
          });
        });
    
  };

  const handleSubmit = async () => {
    axios
      .post(
        `https://kidultsports.herokuapp.com/api/app/class/drop/${removeClass}`,
        {
          student: {
            userId: studentId,
            checkedIn: false,
          },
        }
      )
      .then((res) => {
        axios
          .post(
            `https://kidultsports.herokuapp.com/api/app/dropClass/create/`,
            {
              classObject: {
                courseId: courseId,
                classId: removeClass,
                studentId: studentId,
                classData: res.data,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
          });
        toast.info("Student Dropped Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  const getDropClasses = async () => {
    let dropStudents = [];
    axios
      .get(`https://kidultsports.herokuapp.com/api/app/dropClasses`)
      .then((res) => {
        let AllData = res.data;

        AllData.map((data) => {
          let pushData = {
            studentId: data.classObject.studentId,
            classData: data.classObject.classData,
            Ids: {
              classId: data.classObject.classData._id,
              studentId: data.classObject.studentId,
            },
          };
          dropStudents.push(pushData);
        });
      });
    setAllDropStudents(dropStudents);
  };

  useEffect(() => {
    getDropClasses();
    let allStudentsTempArray = [];
    axios.get("https://kidultsports.herokuapp.com/api/auth/users").then((e) => {
      for (let i = 0; i < e.data.length; i++) {
        if (
          e.data[i].role !== "tutor" &&
          e.data[i].role !== "admin" &&
          e.data[i].role !== "parent"
        ) {
          allStudentsTempArray.push(e.data[i]);
        }
      }
      let sortedBasedOnName = allStudentsTempArray.sort((a, b) => a.FirstName > b.FirstName ? 1 : -1);
      setStudents(sortedBasedOnName);
    });
    axios
      .get("https://kidultsports.herokuapp.com/api/app/courses")
      .then((e) => {
        setAllCourses(e.data);
      });
  }, []);
  return (
    <div>
      <h2 className="page-header">Drop Students</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
     
        <select
          onChange={(e) => getClasses(e.target.value)}
          className="form-control"
        >
          <option>1. Select Course</option>

          {allCourses.map((data, key) => (
            <option value={data._id} key={key}>
              {data.courseData.courseName}
            </option>
          ))}
        </select>
        <select
          value={studentId}
          onChange={(e) => setStudentId(e.target.value)}
          className="form-control"
        >
          <option>2. Select Student</option>

          {students.map((data, key) => (
            <option value={data._id} key={key}>
              {data.FirstName}
            </option>
          ))}
        </select>

        <select
          onChange={(e) => setRemoveClass(e.target.value)}
          className="form-control"
        >
          <option>3. Select Class</option>

          {classes.map((data, key) => (
            <option value={data._id} key={key}>
              {data.classData.className}{"-"}{data.classData.classDate}
            </option>
          ))}
        </select>

        <div onClick={handleSubmit} className="add_button">
          <p>Drop Student</p>
          <i className={`bx bx-user`}></i>
        </div>
      </div>
      <div className="card">
        <div className="card__body">
          {AllDropStudents.length > 0 ? (
            <Table
              limit="20"
              headData={[
                "Student Name",
                "Class name",
                "Class description",
                "Class Date",
              ]}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={AllDropStudents}
              renderBody={(item, index) => <RenderBody data={item} />}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
