import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "../assets/css/login.css";
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit() {
    axios
      .post("https://kidultsports.herokuapp.com/api/auth/signin", {
        email: email,
        password: password,
      })

      .then((res) => {
        if (res.status === 200 && res.data.data.role === "admin") {
          let userId = res.data._id;
          localStorage.setItem("userId", userId);
          localStorage.setItem("isLoggedIn", "true");
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error("Invalid Credentials");
      });
  }
  return (
    <div className="login-box">
      <img
        style={{
          width: "200px",
          height: "80px",
          margin: "0 auto",
          display: "block",
          marginTop: "0px",
          marginBottom: "30px",
        }}
        src="https://i2.wp.com/www.kidultsports.com/wp-content/uploads/2020/09/Kidult-logo_white.png?resize=2048%2C684&ssl=1"
        alt=""
      />
      <form>
        <div className="user-box">
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            name=""
          />
          <label>Email</label>
        </div>
        <div className="user-box">
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            name=""
          />
          <label>Password</label>
        </div>
        <a onClick={() => handleSubmit()}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Submit
        </a>
      </form>
    </div>
  );
}
