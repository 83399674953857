import React, { useEffect, useState } from "react";
import Input from "../components/Input/Input";
import moment from "moment";
import { Widget } from "@uploadcare/react-widget";
import { toast } from "react-toastify";
import axios from "axios";
import { Col, Card, Container, Row, Button } from "react-bootstrap";

export default function AddNews() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [newsDate, setNewsDate] = useState("");
  const [news, setAllNews] = useState([]);

  const handleSubmit = async () => {
    if (title === "" || description === "" || image === "" || newsDate === "") {
      toast.error("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      let data = {
        title: title,
        image: image,
        description: description,
        newsDate: newsDate,
      };
      axios
        .post("https://kidultsports.herokuapp.com/api/app/news/create", data)
        .then((res) => {
          if (res.data.success === true) {
            toast.info("News Added Successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        });
    }
  };
  const getNews = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/news/get")
      .then((res) => {
        setAllNews(res.data);
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      <div>
        <h2 className="page-header">News</h2>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "left",
          }}
        >
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
            icon="news"
          />
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            icon="notepad"
          />
          <Input
          type="date"
          value={newsDate}
          onChange={(e) => setNewsDate(e.target.value)}
          placeholder="News Date"
          icon="calendar"
        />
        
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Widget
            onChange={(info) => {
              setImage(info.cdnUrl);
            }}
            publicKey="96f3fcac48c20a4671a9"
            clearable
          />
          <div onClick={handleSubmit} className="add_button">
            <p>Add News</p>
            <i className={`bx bx-plus`}></i>
          </div>
        </div>
      </div>
      <Container fluid className="main-content-container px-4">
        <br />
        <br />
        <h2 className="page-header">All News</h2>

        <Row>
          {news.map((data, key) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={key}>
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{
                    cursor: "pointer",
                    backgroundImage: `url(${data.image})`,
                    width: "20rem",
                    backgroundSize: "cover",

                    height: "15rem",
                  }}
                >
                  <div
                    onClick={() => {
                      let id = data._id;
                      axios
                        .delete(
                          `https://kidultsports.herokuapp.com/api/app/news/delete/${id}`
                        )
                        .then((res) => {
                          window.location.reload();
                        });
                    }}
                    className="remove_button"
                  >
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Delete
                    </p>
                  </div>

                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{
                        backgroundImage: `url('${data.image}')`,
                      }}
                    ></a>
                  </div>
                </div>
                <Card.Body>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {data.title}
                    </a>
                  </h5>
                  <p
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {data.description}
                  </p>
                  <p
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    <span
                    style={{
                      color: "gray",
                    }}
                    className="text-muted"
                  >
                    {moment(data.newsDate).format("MM/DD/YYYY")}
                  </span>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
