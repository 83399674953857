import axios from "axios";
import React, { useEffect, useState } from "react";
import Input from "../components/Input/Input";
import { toast } from "react-toastify";
import Table from "../components/table/Table";
import ModalVideo from "react-modal-video";
import YoutubeEmbed from "../components/YouTubeEmded";
//import { Table, Card, Container, Row, Button } from "react-bootstrap";
import moment from "moment";
import "../../node_modules/react-modal-video/css/modal-video.css";

export default function AddVideo() {
  const [youTube, setYouTube] = useState("");
  const [youTubeId, setYouTubeId] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [AllVideos, setAllVideos] = useState("");
  const [totalvideo, settotalvideo] = useState("");

  const openYouTube = () => {
    let link = "https://www.youtube.com/";
    window.open(link, "_blank");
  };
  const handleSubmit = (e) => {
    if (youTube === "") {
      toast.error("Please add youtube link", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      axios
        .post("https://kidultsports.herokuapp.com/api/app/youtube/create", {
          youtubeURL: youTube,
        })
        .then((e) => {
          if (e.data.success === true) {
            toast.success("Video added successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        })
        .catch((err) => {
          toast.error("Error adding video", {
            position: "bottom-right",
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    }
  };
  const preview = (e) => {
    setOpen(true);
    const youTubeId = youTube.split("=")[1];
    setYouTubeId(youTubeId);
  };
  
  const gettotalvideo = async () => {
    axios
    .get("https://kidultsports.herokuapp.com/api/app/youtube")
      .then((res) => {
        let gettotalvideo = res.data;
        let sortedvBasedOnDate = gettotalvideo.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        settotalvideo(sortedvBasedOnDate);
      });
  };

  const getVideo = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/youtube")
      .then((e) => {
        let AllVideos = e.data;
        let sortedBasedOnDate = AllVideos.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        let url = sortedBasedOnDate[0].youtubeURL;
        const youTubeId = url.split("=")[1];
        setAllVideos(youTubeId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteItem = (id) => {
    axios
      .delete(
        `https://kidultsports.herokuapp.com/api/app/youtube/delete/${id}`
      )
      .then((res) => {
        toast.info("Youtube Deleted Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        toast.error("Internal Server Error, Please Try Again");
      });
  };


  useEffect(() => {
    getVideo();
    gettotalvideo();
  }, []);

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  const renderBody = (item, index) => {
    return (
      <tr onClick={() => {}} style={{ cursor: "pointer" }} key={index}>
        <>
          <td>{moment(item.createdAt).format("MM/DD/YYYY HH:mm")}</td>
          <td>{item.youtubeURL}</td>
          <td
            onClick={() => {
              deleteItem(item._id);
            }}
            style={{
              color: "red",
            }}
          >
            Delete Item
          </td>
        </>
      </tr>
    );
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Input
          value={youTube}
          onChange={(e) => setYouTube(e.target.value)}
          placeholder="Youtube URL"
          icon="video"
        />
        <div onClick={youTube ? preview : openYouTube} className="add_button">
          <p>{youTube ? "Preview" : "YouTube"}</p>
          <i className={youTube ? "bx bx-play" : "bx bx-link-external"}></i>
        </div>
      </div>
      <div onClick={handleSubmit} className="add_button">
        <p>Save Video</p>
        <i className={`bx bx-video`}></i>
      </div>
    
     
      <br /><br />
      <h2 className="page-header">Current Apps Video</h2>      
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={youTubeId}
        onClose={() => setOpen(false)}
      />
      {AllVideos ? <YoutubeEmbed embedId={AllVideos} /> : null}

      {totalvideo.length > 0 ? (
        <div className="card">
          <div className="card__body">
            <Table
              limit="20"
              headData={[
                "Create Date",
                "Youtube Link",
              ]}
              renderHead={(item, index) => renderHead(item, index)}
              bodyData={totalvideo}
              renderBody={(item, index) => renderBody(item, index)}
            />
          </div>
        </div>
      ) : null}
      <p></p>
     
    </>
  );
}

