import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Input from "../components/Input/Input";
import axios from "axios";
import { Widget } from "@uploadcare/react-widget";
import { Col, Card, Container, Row } from "react-bootstrap";
export default function AddStudent() {
  const [noticeTitle, setNoticeTitle] = useState("");
  const [noticeDescription, setNoticeDescription] = useState("");
  const [noticeDate, setNoticeDate] = useState("");
  const [noticeCreatedBy, setNoticeCreatedBy] = useState("");
  const [noticeImage, setNoticeImage] = useState("");
  const [to, setTo] = useState("all");
  const [allNotices, setAllNotices] = useState([]);

  const [AllUsers, setAllUsers] = useState([]);
  const [AllClasses, setAllClasses] = useState([]);
  const [sendToStudent, setsendToStudent] = useState("");
  const [sendToClass, setsendToClass] = useState("");

  var today = new Date().toISOString().slice(0, 10);

  const handleSubmit = () => {
    if (noticeTitle == "" || noticeDescription == "") {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      if (to == "user") {
        let userId = sendToStudent;
        let allTrue = false;
        saveToNoticeCollection(allTrue);
      } else if (to == "all") {
        let allTrue = true;
        saveToNoticeCollection(allTrue);
      } else if (to == "class") {
        let classId = sendToClass;
        axios
          .get(`https://kidultsports.herokuapp.com/api/app/class/${classId}`)
          .then((res) => {
            let allTrue = false;
            let classUsers = res.data.students;
           // classUsers.forEach((user) => {
              
              saveToNoticeCollection(allTrue);
            //});
           // saveToNoticeCollection(allTrue);
          });
      }
    }
  };

  const saveToNoticeCollection = (allTrue) => {
    let data = {
      noticeData: {
        sendToAll: allTrue,
        sendUser: sendToStudent,
        classId: sendToClass,
        Title: noticeTitle,
        Description: noticeDescription,
        Date: today,
        image:
          noticeImage ||
          "https://endodonticsonly.com.au/wp-content/uploads/placeholder.png",
      },
      readBy: [],
      createdBy: noticeCreatedBy,
    };
    if (noticeTitle == "" || noticeDescription == "" || noticeCreatedBy == "") {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      axios
        .post("https://kidultsports.herokuapp.com/api/app/notice/create", data)
        .then((res) => {
          toast.info("Notice send successfully", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        });
    }
  };


  const getAllUsers = async () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/auth/users")
      .then((res) => {
        let sortedBasedOnDate = res.data.filter((user) => user.role === "student")
        let sortedBasedOnName = sortedBasedOnDate.sort((a, b) => a.FirstName > b.FirstName ? 1 : -1);
        setAllUsers(sortedBasedOnName)
        
      });
  };

  const getAllClasses = async () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/classes")
      .then((res) => {
        setAllClasses(res.data);
      });
  };

  useEffect(() => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/notices")
      .then((e) => {
        setAllNotices(e.data);
      });
    getAllUsers();
    getAllClasses();
  }, []);
  return (
    <>
      <h2 className="page-header">Send Notice to all Users</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Input
          value={noticeTitle}
          onChange={(e) => {
            setNoticeTitle(e.target.value);
          }}
          placeholder="Notice Title"
          icon="news"
        />

        <Input
          value={noticeDate}
          onChange={(e) => {
            setNoticeDate(e.target.value);
          }}
          placeholder={today}
          icon="calendar"
        />
        <Input
          value={noticeCreatedBy}
          onChange={(e) => {
            setNoticeCreatedBy(e.target.value);
          }}
          placeholder="Author"
          icon="user"
        />
        <select
          value={to}
          onChange={(e) => {
            setTo(e.target.value);
          }}
          className="form-control"
        >
          <option value="all">To All</option>
          <option value="user">To Individual User </option>
          <option value="class">To Individual Class</option>
        </select>

        {to == "user" ? (
          <select
            value={sendToStudent}
            onChange={(e) => {
              setsendToStudent(e.target.value);
            }}
            className="form-control"
          >
            <option value="">Select User</option>
            {AllUsers.map((e) => {
              return (
                <option value={e._id}>{e.FirstName + " " + e.LastName}</option>
              );
            })}
          </select>
        ) : to == "class" ? (
          <select
            value={sendToClass}
            onChange={(e) => {
              setsendToClass(e.target.value);
            }}
            className="form-control"
          >
            <option value="">Select Class</option>
            {AllClasses.map((e) => {
              return <option value={e._id}>{e.classData.className}</option>;
            })}
          </select>
        ) : null}

        <div className={`input_containertext `}>
          <textarea
            value={noticeDescription}
            onChange={(e) => {
              setNoticeDescription(e.target.value);
            }}
            placeholder="Notice Description"
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Widget
          onChange={(info) => {
            setNoticeImage(info.cdnUrl);
          }}
          publicKey="96f3fcac48c20a4671a9"
          clearable
        />
        <div onClick={handleSubmit} className="add_button">
          <p>Send Notice</p>
          <i className={`bx bx-plus`}></i>
        </div>
      </div>

      <Container fluid className="main-content-container px-4">
        <br />
        <br />
        <h2 className="page-header">Past Notices</h2>

        <Row>
          {allNotices.map((data, key) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={key}>
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{
                    cursor: "pointer" ,
                    backgroundImage: `url(${data.noticeData.image})`,
                    width: "20rem",
                    backgroundSize: "cover",

                    height: "15rem",
                  }}
                >
                  <div
                    onClick={() => {
                      let id = data._id;
                      axios
                        .delete(
                          `https://kidultsports.herokuapp.com/api/app/notices/delete/${id}`
                        )
                        .then((res) => {
                          window.location.reload();
                        });
                    }}
                    className="remove_button"
                  >
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Delete
                    </p>
                  </div>

                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{
                        backgroundImage: `url('${data.noticeData.image}')`,
                      }}
                    ></a>
                  </div>
                </div>
                <Card.Body>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {data.noticeData.Title}
                    </a>
                  </h5>
                  <p
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {data.noticeData.Description}
                  </p>
                  <span
                    style={{
                      color: "gray",
                    }}
                    className="text-muted"
                  >
                   {moment(data.noticeData.Date).format("MM/DD/YYYY HH:mm")}
                    
                  </span>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
