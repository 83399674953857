import React from "react";
import "../assets/css/index.css";
import backgroundImage from "../assets/background.png";
import moment from "moment";

export default function generateInvoice() {
  // Getting user name from parameter in url
  
  let link = new URL(window.location.href);
  let invoiceNumber = link.searchParams.get("invoiceNumber");

  //http://localhost:3000/generate-invoice?invoiceNumber=87463&invoiceDate=05/01/2022&invoiceTotal=4500&invoiceTo=Zahed Kakar&invoiceToEmail=zahed@gmail.com&invoiceToPhone=+919963672969&invoiceCourseName=Football&invoiceCoursePrice=3000&membershipType=Premium&membershipPrice= 1500
  let invoiceDate = link.searchParams.get("invoiceDate");
  let invoiceTotal = link.searchParams.get("invoiceTotal");
  let invoiceTo = link.searchParams.get("invoiceTo");
  let invoiceToEmail = link.searchParams.get("invoiceToEmail");
  let invoiceToPhone = link.searchParams.get("invoiceToPhone");
  let invoiceCourseName = link.searchParams.get("invoiceCourseName");
  let invoiceCoursePrice = link.searchParams.get("invoiceCoursePrice");
  let membershipType = link.searchParams.get("membershipType");
  let membershipPrice = link.searchParams.get("membershipPrice");

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "-297px",
          top: "0px",
          marginTop: "50px",

          width: "600px",
          height: "841px",
          border: "1px solid #000000",
          overflow: "hidden",
        }}
      >
        <div style={{ position: "absolute", left: "0px", top: "0px" }}>
          <img src={backgroundImage} width={595} height={841} />
        </div>
        <div
          style={{ position: "absolute", left: "309.94px", top: "70.35px" }}
          className="cls_003"
        >
          <span className="cls_003">INVOICE</span>
        </div>
        <div
          style={{ position: "absolute", left: "322.30px", top: "162.78px" }}
          className="cls_004"
        >
          <span className="cls_004">Invoice To:</span>
        </div>
        <div
          style={{ position: "absolute", left: "322.30px", top: "183.31px" }}
          className="cls_005"
        >
          <span className="cls_005">{invoiceTo}</span>
        </div>
        <div
          style={{ position: "absolute", left: "322.30px", top: "217.71px" }}
          className="cls_006"
        >
          <span className="cls_006">TEL: {invoiceToPhone}</span>
        </div>
        <div
          style={{ position: "absolute", left: "322.30px", top: "233.71px" }}
          className="cls_006"
        >
          <span className="cls_006">MAIL: {invoiceToEmail}</span>
        </div>
        <div
          style={{ position: "absolute", left: "230.27px", top: "298.36px" }}
          className="cls_007"
        >
          <span className="cls_007">DESCRIPTION</span>
        </div>
        <div
          style={{ position: "absolute", left: "400.85px", top: "298.36px" }}
          className="cls_007"
        >
          <span className="cls_007">QTY</span>
        </div>
        <div
          style={{ position: "absolute", left: "450.65px", top: "298.36px" }}
          className="cls_007"
        >
          <span className="cls_007">PRICE</span>
        </div>

        <div
          style={{ position: "absolute", left: "60.91px", top: "299.62px" }}
          className="cls_010"
        >
          <span
            style={{
              color: "#fff",
            }}
          >
            INVOICE NO
          </span>
        </div>
        <div
          style={{ position: "absolute", left: "62.50px", top: "322.62px" }}
          className="cls_008"
        >
          <span className="cls_008">#{invoiceNumber}</span>
        </div>
        <div
          style={{ position: "absolute", left: "230.77px", top: "323.96px" }}
          className="cls_006"
        >
          <span className="cls_006"></span>
        </div>
        <div
          style={{
            position: "absolute",
            left: "230.77px",
            top: "335.96px",
            width: "130px",
          }}
          className="cls_006"
        >
          <span className="cls_006">{invoiceCourseName}</span>
        </div>
        <div
          style={{ position: "absolute", left: "400.63px", top: "341.96px" }}
          className="cls_006"
        >
          <span className="cls_006">1</span>
        </div>
        <div
          style={{ position: "absolute", left: "450.01px", top: "341.96px" }}
          className="cls_006"
        >
          <span className="cls_006">HK${invoiceCoursePrice}</span>
        </div>

        <div
          style={{ position: "absolute", left: "60.77px", top: "354.04px" }}
          className="cls_010"
        >
          <span className="cls_010">INVOICE DATE</span>
        </div>

        <div
          style={{ position: "absolute", left: "63.04px", top: "376.04px" }}
          className="cls_008"
        >
          <span className="cls_008">
            {moment(invoiceDate).format("DD/MM/YYYY")}
          </span>
        </div>
        <div
          style={{ position: "absolute", left: "60.90px", top: "404.55px" }}
          className="cls_010"
        >
          <span className="cls_010">TOTAL DUE</span>
        </div>
        <div
          style={{ position: "absolute", left: "59.88px", top: "427.55px" }}
          className="cls_008"
        >
          <span className="cls_008">HK${invoiceCoursePrice}</span>
        </div>
        <div
          style={{ position: "absolute", left: "66.66px", top: "589.20px" }}
          className="cls_011"
        >
          <span className="cls_011">Contact:</span>
        </div>
        <div
          style={{ position: "absolute", left: "425.85px", top: "605.69px" }}
          className="cls_012"
        >
          <span className="cls_012">TOTAL</span>
        </div>
        <div
          style={{ position: "absolute", left: "499.15px", top: "605.69px" }}
          className="cls_006"
        >
          <span className="cls_006">HK${invoiceCoursePrice}</span>
        </div>
        <div
          style={{ position: "absolute", left: "61.14px", top: "626.60px" }}
          className="cls_008"
        >
          <span className="cls_008">RM102, 15/F,</span>
        </div>
        <div
          style={{ position: "absolute", left: "230.27px", top: "637.99px" }}
          className="cls_013"
        >
          <span className="cls_013">TERMS</span>
        </div>
        <div
          style={{ position: "absolute", left: "41.61px", top: "645.60px" }}
          className="cls_008"
        >
          <span className="cls_008">KWUN TONG VIEW,</span>
        </div>
        <div
          style={{ position: "absolute", left: "230.52px", top: "664.43px" }}
          className="cls_013"
        >
          <span className="cls_014">
            {" "}
            Please bank in the tuition fees to HSBC: 121-829394-838 (KIDULT
            SPORTS LTD.) and send back the receipt to us through whatsapp /
            email. Please Skip this message if you paid.{" "}
          </span>
        </div>
        <div
          style={{ position: "absolute", left: "77.43px", top: "664.60px" }}
          className="cls_008"
        >
          <span className="cls_008">KLN. HK</span>
        </div>

        <div
          style={{ position: "absolute", left: "230.52px", top: "710.36px" }}
          className="cls_014"
        >
          <span>請將費⽤轉帳⾄匯豐銀⾏：121-829394-838 (KIDULT SPORTS LTD.)並將收據
           </span>
        </div>
        <div
          style={{ position: "absolute", left: "42.38px", top: "721.22px" }}
          className="cls_010"
        >
          <span className="cls_010">T:</span>
          <span className="cls_008"> +852 5419 4911</span>
        </div>
        <div
          style={{ position: "absolute", left: "230.52px", top: "724.76px" }}
          className="cls_014"
        >
          <span className="cls_014">傳送到我們WHATSAPP或電郵。如果您已付款可以不⽤理會這段訊息。</span>
        </div>
        <div
          style={{ position: "absolute", left: "40.83px", top: "740.22px" }}
          className="cls_010"
        >
          <span className="cls_010">E: </span>
          <span className="cls_015">info@kidultsports.com</span>
        </div>
        <div
          style={{ position: "absolute", left: "40.66px", top: "759.22px" }}
          className="cls_010"
        >
          <span className="cls_010">W:</span>
          <span className="cls_015"> www.kidultsports.com</span>
        </div>
        <div
          style={{ position: "absolute", left: "210.63px", top: "776.33px" }}
          className="cls_016"
        >
          <span className="cls_016">
            T: 5419 4911 &nbsp;&nbsp;E:Info@kidultsports.com&nbsp;&nbsp;W:kidultsports.com</span>
        </div>
      </div>
    </div>
  );
}
