import React, { useState } from "react";
import { toast } from "react-toastify";
import Input from "../components/Input/Input";
import axios from "axios";
import { Widget } from "@uploadcare/react-widget";
import AllTutor from "./AllTutor";
export default function AddStudent() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+852");
  const [dateofbirth, setDateofbirth] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [profilepic, setProfilePic] = useState("");

  const [location, setLocation] = useState("");
  const [HKIDNumber, setHKIDNumber] = useState("");
  const [bankAccount, setbankAccount] = useState("");
  const [budget, setBudget] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [AllLocation, setAllLocations] = useState([]);
  const [courseLocationNew, setCourseLocationNew] = useState("");

 
  
  const handleSubmit = () => {
    let data = {
      Email: email,
      Password: password,     
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      StreetAddressLine2: address,
      DateOfBirth: dateofbirth,
      profile:
      profilepic ||
      `https://ui-avatars.com/api/?size=512&name=${
        firstName
      }&background=9cd5d5&color=fff`,
      role: "tutor",
      userData: {
        location: location,
        HKIDNumber: HKIDNumber,
        bankAccount: bankAccount,
        budget: budget,
      },
    };
    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      phone == "" ||
      password == "" ||
      confirmPassword == "" ||
      address == ""
    ) {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      if (password !== confirmPassword) {
        toast.info("Password and confirm password should match", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {

      
        axios
          .post("https://kidultsports.herokuapp.com/api/auth/signup", data)
          .then((res) => {
            toast.info("User added successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
          .catch((error) => {
            console.log(error);
          });


      }

    }
  };
  const getAllLocations = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/locations/all")
      .then((res) => {
        setAllLocations(res.data);
      });
  };


  return (
    <>
      <h2 className="page-header">Add Tutor</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Input
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          placeholder="First Name"
          icon="user"
        />
        <Input
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          placeholder="Last Name"
          icon="user"
        />
      
        <Input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Email Address"
          icon="envelope"
        />
        <Input
          value={password}
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
          icon="lock"
        />
        <Input
          value={confirmPassword}
          type="password"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          placeholder="Confirm Password"
          icon="lock"
        />{" "}
        <Input
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder="Phone Number"
          icon="phone"
        />
        <Input
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          placeholder="Address"
          icon="map"
        />
        <Input
          type="date"
          value={dateofbirth}
          onChange={(e) => {
            setDateofbirth(e.target.value);
          }}
          placeholder="Date of Birth"
          icon="calendar"
        />
        <Input
          value={bankAccount}
          onChange={(e) => {
            setbankAccount(e.target.value);
          }}
          placeholder="Bank Account"
          icon="food-menu"
        />
        
        <Input
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
          placeholder="Location"
          icon="map"
        />
        
        <Input
          value={budget}
          onChange={(e) => {
            setBudget(e.target.value);
          }}
          placeholder="Budget"
          icon="dollar"
        />
        <Input
          value={HKIDNumber}
          onChange={(e) => {
            setHKIDNumber(e.target.value);
          }}
          placeholder="HKID Number"
          icon="notepad"
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Widget
          onChange={(info) => {
            setProfilePic(info.cdnUrl);
          }}
          publicKey="96f3fcac48c20a4671a9"
          clearable
        />
        <div onClick={handleSubmit} className="add_button">
          <p>Add Tutor</p>
          <i className={`bx bx-plus`}></i>
        </div>
      </div>
      <AllTutor />
    </>
  );
}
