import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "../components/Input/Input";
import axios from "axios";
import { Widget } from "@uploadcare/react-widget";
import Modal from "react-modal";
import { Col, Card, Container, Row } from "react-bootstrap";
import moment from "moment";
export default function AddClass() {
  const [allCourses, setAllCourses] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [courseIdForInfo, setCourseIdForInfo] = useState(null);
  const [AllLocation, setAllLocations] = useState([]);

  const [allClasses, setAllClasses] = useState([]);
  const [classImage, setClassImage] = useState("");
  const [className, setClassName] = useState("");
  const [classDate, setClassDate] = useState("");
  const [classDateto, setClassDateto] = useState("");
  const [classTime, setClassTime] = useState("");
  const [classLocation, setClassLocation] = useState("");
  const [classDescription, setClassDescription] = useState("");
  const [tutorName, setTutorName] = useState("");

  // Update Class
  const [updateClassName, setUpdateClassName] = useState("");
  const [updateClassImage, setUpdateClassImage] = useState("");
  const [updateClassDate, setUpdateClassDate] = useState("");
  const [updateClassDateto, setUpdateClassDateto] = useState("");
  const [updateClassTime, setUpdateClassTime] = useState("");
  const [updateClassLocation, setUpdateClassLocation] = useState("");
  const [updateClassDescription, setUpdateClassDescription] = useState("");
  const [updateModal, setUpdateModal] = useState("");
  const [updateClassData, setUpdateClassData] = useState(null);

  const handleSubmit = (e) => {
    
    if (classImage == "" || className == "") {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      saveCourseToDB();
    }

  };

  const getAllLocations = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/locations/all")
      .then((res) => {
        setAllLocations(res.data);
      });
  };

  const saveCourseToDB = async () => {
    const response = await axios.get(
      `https://kidultsports.herokuapp.com/api/app/course/${courseId}`
    );
    const cdate = [classDate];
    for (let i=1; i<= moment(classDateto).diff(moment(classDate), 'day') / 7; i++)
    {
    cdate.push(moment(classDate).add(i*7, 'day').format('YYYY-MM-DD'));
    }
    cdate.forEach((d) => {
    let newClass = {
      classImage: classImage,
      classLocation: classLocation,
      className: className,
      classDescription: classDescription,
      tutorName: tutorName,
      classDate: d,
      classDateto: moment(classDateto).diff(moment(classDate), 'day'),    
      coursePrice: response.data.courseData.coursePrice,
      classTime: classTime,
    };
  
    if (classImage == "" || classLocation == ""  || className == ""  || classDateto =="" || classDate =="" || classTime =="" ) {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {

    axios
      .post("https://kidultsports.herokuapp.com/api/app/class/create", {
        classData: newClass,
        students: [], 
        tutor: {},
      })
      .then((res) => {
        addClassToCourse(res.data._id);
      });

    };
       });
     
  }


  const addClassToCourse = async (classId) => {
    axios
      .post(
        `https://kidultsports.herokuapp.com/api/app/course/add-class/${courseId}/${classId}`
      )
      .then((res) => {
        toast.info("Class Added Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  const getClassAndCourse = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/courses")
      .then((e) => {
        setAllCourses(e.data);
      });

    axios
      .get("https://kidultsports.herokuapp.com/api/app/classes")
      .then((e) => {
        let sortedBasedOnDate = e.data.sort((a, b) => {
          return new Date(b.classData.classDate) - new Date(a.classData.classDate);
        });
        setAllClasses(sortedBasedOnDate);
      });
  };

  useEffect(() => {
    getClassAndCourse();
    getAllLocations();
  }, []);

  const updateClass = (classid) => {
    let classData = {
      className: updateClassName || updateClassData.classData.className,
      classDate: updateClassDate || updateClassData.classData.classDate,
      classTime: updateClassTime || updateClassData.classData.classTime,
      classLocation:
        updateClassLocation || updateClassData.classData.classLocation,
      classImage: updateClassImage || updateClassData.classData.classImage,
      classDescription:
        updateClassDescription || updateClassData.classData.classDescription,
      coursePrice: updateClassData.classData.coursePrice,
    };
    axios
      .put(
        `https://kidultsports.herokuapp.com/api/app/class/update-class-data/${classid}`,
        {
          classData: classData,
        }
      )
      .then((res) => {
        toast.info("Class Updated Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  return (
    <>
      <h2 className="page-header">Add Class</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Input
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          placeholder="Class Name"
          icon="book"
        />
        <Input
          value={classDescription}
          onChange={(e) => setClassDescription(e.target.value)}
          placeholder="Class Description"
          icon="notepad"
        />
        <Input
          value={classTime}
          type="time"
          onChange={(e) => setClassTime(e.target.value)}
          placeholder="Class Time"
          icon="time"
        />
        <select
          value={classLocation}
          onChange={(e) => setClassLocation(e.target.value)}
          className="form-control"
        >
          <option>Select Location</option>
          {AllLocation.map((location) => {
            return <option value={location.name}>{location.name}</option>;
          })}
        </select>
        <Input
          type="date"
          value={classDate}
          onChange={(e) => setClassDate(e.target.value)}
          placeholder="Class Date"
          icon="calendar"
        />
         <Input
          type="date"
          value={classDateto}
          onChange={(e) => setClassDateto(e.target.value)}
          placeholder="Class Date To"
          icon="calendar"
        />


        <select
          value={courseId}
          onChange={(e) => setCourseId(e.target.value)}
          className="form-control"
        >
          <option>Select Course</option>

          {allCourses.map((data, key) => (
            <option value={data._id} key={key}>
              {data.courseData.courseName}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Widget
          onChange={(info) => {
            setClassImage(info.cdnUrl);
          }}
          publicKey="96f3fcac48c20a4671a9"
          clearable
        />
        <div onClick={handleSubmit} className="add_button">
          <p>Add a Class</p>
          <i className={`bx bx-plus`}></i>
        </div>
      </div>
      <Container fluid className="main-content-container px-4">
        <br />
        <br />
        <h2 className="page-header">All Classes (Classes can only be deleted if tutor or student not check-in)</h2>
        <Row>
          {allClasses.map((data, key) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={key}>
              <Card small className="card-post card-post--1">
                <div                  
                  className="card-post__image"
                  style={{
                    cursor: "pointer",
                    backgroundImage: `url(${data.classData.classImage})`,
                    width: "20rem",
                    backgroundSize: "cover",
                    height: "15rem",
                  }}
                >
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{
                        backgroundImage: `url('${data.classData.classImage}')`,
                      }}
                    ></a>
                  </div>
                  <div
                    onClick={() => {
                      setUpdateClassData(data);

                      setUpdateModal(true);
                    }}
                    style={{
                      backgroundColor: "#9CD5D5",
                      borderRadius: "6px",
                    }}
                    className="remove_button"
                  >
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Edit
                    </p>
                  </div>
                </div>
                <Card.Body>
                  <h5 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {data.classData.className}
                    </a>
                  </h5>
                  <p style={{ wordWrap: "break-word", width: "20rem" }}>
                    {data.classData.classDescription.substring(0, 100)}
                    {data.classData.classDescription.length > 100 ? "..." : ""}
                  </p>
                  <span
                    style={{
                      color: "gray",
                    }}
                    className="text-muted"
                  >
                    {data.classData.classDate}&nbsp;&nbsp;{data.classData.classTime}
                  </span>
                  <p
                    style={{
                      color: "gray",
                    }}
                    className="text-muted"
                  >
                    {data.tutorCheckIn
                      ? "Tutor Checked In ✅ "
                      : "Tutor Didn't Checked In Yet"}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {updateClassData ? (
        <Modal
          isOpen={updateModal}
          onRequestClose={() => setUpdateModal(false)}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              width: "25%",
              bottom: "auto",
              backgroundColor: "#FAFAFB",
              border: "none",
              overflow: "visible",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div className="modal-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Class Name
              </p>
              <input
                className="modal-input"
                defaultValue={updateClassData.classData.className}
                onChange={(e) => setUpdateClassName(e.target.value)}
              />
              <p style={{ marginTop: "10px", fontSize: "13px" }}>
                {" "}
                Class Description
              </p>
              <input
                className="modal-input"
                defaultValue={updateClassData.classData.classDescription}
                onChange={(e) => setUpdateClassDescription(e.target.value)}
              />
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Class Time
              </p>
              <input
                className="modal-input"
                defaultValue={updateClassData.classData.classTime}
                onChange={(e) => setUpdateClassTime(e.target.value)}
              />
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Class Date
              </p>
              <input
                className="modal-input"
                defaultValue={updateClassData.classData.classDate}
                onChange={(e) => setUpdateClassDate(e.target.value)}
              />
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "13px",
                }}
              >
                Location
              </p>
              <select
                value={
                  updateClassLocation || updateClassData.classData.classLocation
                }
                className="modal-input"
                onChange={(e) => setUpdateClassLocation(e.target.value)}
              >
                <option>Select Location</option>
                {AllLocation.map((location) => {
                  return <option value={location.name}>{location.name}</option>;
                })}
              </select>
              <br />
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  marginLeft: "5rem",
                  marginBottom: "15px",
                }}
              >
                <Widget
                  onChange={(info) => {
                    setUpdateClassImage(info.cdnUrl);
                  }}
                  publicKey="96f3fcac48c20a4671a9"
                  clearable
                />
              </div>
              <div className="buttonContainer">
                {!updateClassData.tutorCheckIn? (
                <div
                  onClick={() => {
                    let id = updateClassData._id;
                    axios
                      .delete(
                        `https://kidultsports.herokuapp.com/api/app/class/delete/${id}`
                      )
                      .then((res) => {
                        window.location.reload();
                      });
                  }}
                  className="remove_button center"
                >
                  <p>Delete</p>
                  <i className={`bx bx-user-x`}></i>
                </div>
                ) : null}

                <div
                  onClick={() => {
                    updateClass(updateClassData._id);
                  }}
                  className="remove_button center"
                  style={{
                    backgroundColor: "#3895D3",
                  }}
                >
                  <p>Update</p>
                  <i className={`bx bx-user`}></i>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}
