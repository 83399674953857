import React, { useState } from "react";
import { toast } from "react-toastify";
import Input from "../components/Input/Input";
import axios from "axios";
import { Widget } from "@uploadcare/react-widget";
import { Form } from "react-bootstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AllStudents from "./AllStudents";

export default function AddStudent() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+852");
  const [dateofbirth, setDateofbirth] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [profilepic, setProfilePic] = useState("");
  const [role, setRole] = useState("");
  const [source, setSource] = useState("");
  const [facebook, setFacebook] = useState("");
  const [nickName, setNickName] = useState("");
  const [emailexist, setemailexist] = useState("");
  const [phoneexist, setphoneexist] = useState("");
  
  const handleSubmit = () => {
    let data = {
      Email: email,
      Password: password,
      FirstName: firstName,
      nickName: nickName,
      LastName: lastName,
      Phone: phone,
      StreetAddressLine2: address,
      DateOfBirth: dateofbirth,
      profile:
        profilepic ||
        `https://ui-avatars.com/api/?size=512&name=${
          firstName + "+" + lastName
        }&background=9cd5d5&color=fff`,
      source: source,
      role: role,
      facebook: facebook,
    };
    if (firstName == "" || lastName == "" || email == "" || phone == "" || role == "" || password == "" || confirmPassword == "" || address == "") {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } 
    else if  (password !== confirmPassword) {
        toast.info("Password and confirm password should match", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    }
    else if (!phone.includes('+852'))
    {
        toast.info("Please enter +852 for the phone number!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
    }    
    else { 
   /*   axios
      .post("https://kidultsports.herokuapp.com/api/auth/check-email", data.Email)
      .then((res) => {
        alert(data.Email);
        alert(JSON.stringify(res.data.message));
        if (JSON.stringify(res.data.message)==="Email already exists")
        {
          alert(res.data.message);
          toast.info("Email already exists!", {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          setemailexist("yes");
        }
      })     
      alert(emailexist);
      if (emailexist==="" )
      {*/
  
        axios
          .post("https://kidultsports.herokuapp.com/api/auth/signup", data)
          .then((res) => {
            toast.info("User added successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
      //}
    }
    
  };

  return (
    <>
      <h2 className="page-header">Add Students / Parents</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Input
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          placeholder="First Name"
          icon="user"
        />
        <Input
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          placeholder="Last Name"
          icon="user"
        />
        <Input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Email Address"
          icon="envelope"
        />
        <Input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="Password"
          icon="lock"
        />
        <Input
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          type="password"
          placeholder="Confirm Password"
          icon="lock"
        />{" "}
        <Input
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder="Phone Number"
          icon="phone"
        />
        <Input
          value={nickName}
          onChange={(e) => {
            setNickName(e.target.value);
          }}
          placeholder="Nick Name"
          icon="user"
        />
        <Input
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          placeholder="Address"
          icon="map"
        />
        <Input
          type="date"
          value={dateofbirth}
          onChange={(e) => {
            setDateofbirth(e.target.value);
          }}
          placeholder="Date of Birth"
          icon="calendar"
        />
        <select
          value={role}
          onChange={(e) => {
            setRole(e.target.value);
          }}
          className="form-control"
        >
          <option defaultValue>Select Role</option>
          <option value="student">Student</option>
          <option value="admin">Admin</option>
          <option value="parent">Parent</option>
        </select>
        <select
          onChange={(e) => {
            setSource(e.target.value);
          }}
          value={source}
          className="form-control"
        >
          <option defaultValue disabled>
            Select Source
          </option>
          <option value="google">Google</option>
          <option value="facebook">Facebook</option>
          <option value="twitter">Twitter</option>
          <option value="friend">Friend</option>
          <option value="other">Other</option>
        </select>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Widget
          onChange={(info) => {
            setProfilePic(info.cdnUrl);
          }}
          publicKey="96f3fcac48c20a4671a9"
          clearable
        />
        <div onClick={handleSubmit} className="add_button">
          <p>Add User</p>
          <i className={`bx bx-plus`}></i>
        </div>
      </div>
      <AllStudents />
    </>
  );
}
