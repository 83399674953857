import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import Chart from "react-apexcharts";

import { useSelector } from "react-redux";

import StatusCard from "../components/status-card/StatusCard";

import Table from "../components/table/Table";

import Badge from "../components/badge/Badge";
import axios from "axios";

const latestOrders = {
  header: ["order id", "user", "total price", "date"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const orderStatus = {
  shipping: "primary",
  pending: "warning",
  paid: "success",
  refund: "danger",
};

const renderOrderHead = (item, index) => <th key={index}>{item}</th>;

const renderOrderBody = (item, index) => (
  <tr key={index}>
    <td>{item.id}</td>
    <td>{item.user}</td>
    <td>{item.price}</td>
    <td>{item.date}</td>
  </tr>
);

const Dashboard = () => {
  const [allUsers, setAllUsers] = useState("...");
  const [allCourses, setAllCourses] = useState("...");
  const [allClasses, setAllClasses] = useState("...");
  const [totalBalance, setAllBalance] = useState("...");
  const [otherBalance, setotherBalance] = useState("...");

  const themeReducer = useSelector((state) => state.ThemeReducer.mode);

  const getAlUsers = async () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/auth/users")

      .then((res) => {
        setAllUsers(res.data.length);
      })
      .catch((err) => console.log(err));
  };

  const getAllCourses = async () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/courses")
      .then((res) => {
        setAllCourses(res.data.length);
      })
      .catch((err) => console.log(err));
  };

  const getAllClasses = async () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/classes")
      .then((res) => {
        setAllClasses(res.data.length);
      })
      .catch((err) => console.log(err));
  };

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  const getAllBalance = async () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/payments/balance")
      .then((res) => {
        setAllBalance(nFormatter(res.data, 1));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAlUsers();
    getAllCourses();
    getAllClasses();
    getAllBalance();
  }, []);

  let statusCardData = [
    {
      icon: "bx bx-user",
      count: allUsers,
      title: "Total Users",
    },
    {
      icon: "bx bx-book-bookmark",
      count: allCourses,
      title: "Total Courses",
    },
    {
      icon: "bx bx-book-reader",
      count: allClasses,
      title: "Total Classes",
    },
    {
      icon: "bx bx-dollar-circle",
      count: `$${totalBalance}`,
      title: "Total Stripe Payments Received",
    },
  ];

  return (
    <div>
      <h2 className="page-header">Dashboard</h2>
      <div className="row">
        <div className="col-12">
          <div className="row">
            {statusCardData.map((item, index) => (
              <div className="col-3" key={index}>
                <StatusCard
                  icon={item.icon}
                  count={item.count}
                  title={item.title}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <div className="col-6">
          <div className="card full-height">
            <Table
              headData={topCustomers.head}
              renderHead={(item, index) => renderCusomerHead(item, index)}
              bodyData={topCustomers.body}
              renderBody={(item, index) => renderCusomerBody(item, index)}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
