import axios from "axios";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Input from "../components/Input/Input";

export default function AddLocation() {
  const [LocationName, setLocationName] = useState("");
  const [cLocationName, setcLocationName] = useState("");
  const [AllLocation, setAllLocations] = useState([]);

  const getAllLocations = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/locations/all")
      .then((res) => {
        setAllLocations(res.data);
      });
  };

  const addLocation = () => {
    if (LocationName === "" || cLocationName === "" ) {
      toast.info("Please fill in location name", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {

    axios
      .post("https://kidultsports.herokuapp.com/api/app/locations/create", {
        name: LocationName,
        cname: cLocationName,
      })
      .then((res) => {
        getAllLocations();
      });
    }
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  useEffect(() => {
    getAllLocations();
  }, []);

  return (
    <div>
      <Input
        value={LocationName}
        onChange={(e) => {
          setLocationName(e.target.value);
        }}
        placeholder="Location"
        icon="map"
      />
      <Input
        value={cLocationName}
        onChange={(e) => {
          setcLocationName(e.target.value);
        }}
        placeholder="Chinese Location"
        icon="map"
      />
      <div
        onClick={addLocation}
        style={{
          width: "25%",
        }}
        className="add_button"
      >
        <p>Add Location</p>
        <i className={`bx bx-plus`}></i>
      </div>

      <div
        style={{
          marginTop: "30px",
        }}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>

            <th>Location Name</th>
            <th>Chinese Location Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {AllLocation.map((e, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{e.name}</td>
              <td>{e.cname}</td>
              <td
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  axios
                    .delete(
                      `https://kidultsports.herokuapp.com/api/app/locations/delete/${e._id}`
                    )
                    .then(() => {
                      getAllLocations();
                    });
                }}
              >
                Delete
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
