import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "../components/Input/Input";
import axios from "axios";
import { Widget } from "@uploadcare/react-widget";
import Modal from "react-modal";

import { Col, Card, Container, Row } from "react-bootstrap";
export default function AddCourse() {
  const [allTutors, setAllTutors] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [tutorId, setTutorId] = useState("");
  const [modal, setModal] = useState(false);

  const [courseImage, setCourseImage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [courseAddress, setCourseAddress] = useState("");
  const [courseDeadline, setDeadline] = useState("");
  const [courseRequirement, setCourseRequirement] = useState("");
  const [courseLocation, setCourseLocation] = useState("");
  const [courseStatus, setCourseStatus] = useState("");
  

  // Updated Inputs

  const [courseNameNew, setCourseNameNew] = useState("");
  const [courseDescriptionNew, setCourseDescriptionNew] = useState("");
  const [courseTutorNew, setCourseTutorNew] = useState(null);
  const [coursePriceNew, setCoursePriceNew] = useState("");
  const [courseImageNew, setCourseImageNew] = useState("");
  const [courseAddressNew, setCourseAddressNew] = useState("");
  const [courseDeadlineNew, setDeadlineNew] = useState("");
  const [courseRequirementNew, setCourseRequirementNew] = useState("");
  const [courseLocationNew, setCourseLocationNew] = useState("");
  const [courseStatusNew, setCourseStatusNew] = useState("");
  
  const [currentData, setCurrentData] = useState("");
  const [AllLocation, setAllLocations] = useState([]);
 
  const handleSubmit = (e) => {
  
    let data = {
      courseData: {
        courseName: courseName,
        courseDescription: courseDescription,
        courseAddress: courseAddress,
        courseLocation: courseLocation,
        coursePrice: coursePrice,
        courseStatus: courseStatus,
        courseDeadline: courseDeadline,
        courseRequirement: courseRequirement,
        courseImage:
          courseImage ||
          "https://endodonticsonly.com.au/wp-content/uploads/placeholder.png",
        tutorId: tutorId,
      },

      classes: [],
    };    
    if (courseName == "" || courseDescription == ""  || coursePrice == ""  || courseAddress == "" || courseLocation ==""
    || coursePrice =="" || courseStatus =="" || courseDeadline =="" || courseImage == "" ) {
      toast.info("Please fill in all fields", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {

    axios
      .post("https://kidultsports.herokuapp.com/api/app/course/create", data)
      .then((res) => {
        toast.info("Course Added Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        toast.error("Course Not Added");
      });
  };
  }
  const getAllLocations = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/locations/all")
      .then((res) => {
        setAllLocations(res.data);
      });
  };

  const updateCourse = (e) => {
    let courseId = currentData._id;
    axios
      .put(
        "https://kidultsports.herokuapp.com/api/app/course/update/" + courseId,
        {
          courseData: {
            tutorId: courseTutorNew || currentData.courseData.tutorId,
            courseName: courseNameNew || currentData.courseData.courseName,
            courseDescription:
              courseDescriptionNew || currentData.courseData.courseDescription,
            courseAddress:
              courseAddressNew || currentData.courseData.courseAddress,
            courseLocation:
              courseLocationNew || currentData.courseData.courseLocation,
            coursePrice: coursePriceNew || currentData.courseData.coursePrice,
            courseStatus:
              courseStatusNew || currentData.courseData.courseStatus,
            courseDeadline:
              courseDeadlineNew || currentData.courseData.courseDeadline,
            courseImage: courseImageNew || currentData.courseData.courseImage,
          },
        }
      )
      .then((res) => {
        toast.info("Course Updated Successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  useEffect(() => {
    getAllLocations();

    axios
      .get("https://kidultsports.herokuapp.com/api/app/courses")
      .then((e) => {
        setAllCourses(e.data);
      });
    let allTutorsTempArray = [];
    axios.get("https://kidultsports.herokuapp.com/api/auth/users").then((e) => {
      for (let i = 0; i < e.data.length; i++) {
        if (e.data[i].role === "tutor") {
          allTutorsTempArray.push(e.data[i]);
        }
      }
      setAllTutors(allTutorsTempArray);
    });
  }, []);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "25%",
      bottom: "auto",
      backgroundColor: "#FAFAFB",
      border: "none",
      marginRight: "-50%",
      height: "80%",
      overflowX: "hidden",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  return (
    <>
      <h2 className="page-header">Add Course</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Input
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
          placeholder="Course Name"
          icon="book"
        />
        
        <Input
          value={courseAddress}
          onChange={(e) => setCourseAddress(e.target.value)}
          placeholder="Course Address"
          icon="map"
        />
        <Input
          value={courseDescription}
          onChange={(e) => setCourseDescription(e.target.value)}
          placeholder="Course Description"
          icon="notepad"
        />
        <select
          value={courseLocation}
          onChange={(e) => setCourseLocation(e.target.value)}
          className="form-control"
        >
          <option>Select Location</option>
          {AllLocation.map((location) => {
            return <option value={location.name}>{location.name}</option>;
          })}
        </select>
        <Input
          value={coursePrice}
          onChange={(e) => setCoursePrice(e.target.value)}
          placeholder="Course Price"
          icon="dollar"
        />
        <Input
          value={courseDeadline}
          type="date"
          onChange={(e) => setDeadline(e.target.value)}
          placeholder="Course Deadline"
          icon="calendar"
        />
        <select
          value={tutorId}
          onChange={(e) => setTutorId(e.target.value)}
          className="form-control"
        >
          <option>Select Tutor</option>

          {allTutors.map((data, key) => (
            <option value={data._id} key={key}>
              {data.FirstName} {data.LastName}
            </option>
          ))}
        </select>
        <select
          defaultValue={courseStatus}
          onChange={(e) => setCourseStatus(e.target.value)}
          className="form-control"
        >
          <option>Course Status</option>
          <option value="active">Active</option>
          <option value="deactivate">Deactivate</option>
          <option value="terminated">Terminated</option>
        </select>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Widget
          onChange={(info) => {
            setCourseImage(info.cdnUrl);
          }}
          publicKey="96f3fcac48c20a4671a9"
          clearable
        />
        <div onClick={handleSubmit} className="add_button">
          <p>Add Course</p>
          <i className={`bx bx-plus`}></i>
        </div>
      </div>

      <Container fluid className="main-content-container px-4">
        <br />
        <br />
        <h2 className="page-header">All Courses (Courses can only be delete if have 0 classes)</h2>

        <Row>
          {allCourses.map((data, key) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={key}>
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{
                    cursor: "pointer",
                    backgroundImage: `url(${data.courseData.courseImage})`,
                    width: "20rem",
                    backgroundSize: "cover",
                    height: "15rem",
                  }}
                >
                  <div
                    onClick={() => {
                      setCurrentData(data);
                      setModal(true);
                    }}
                    style={{
                      backgroundColor: "#9CD5D5",
                      borderRadius: "6px",
                    }}
                    className="remove_button"
                  >
                    <p
                      style={{
                        color: "white",
                      }}
                    >
                      Edit
                    </p>
                  </div>

                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{
                        backgroundImage: `url('${data.courseData.courseImage}')`,
                      }}
                    ></a>
                  </div>
                </div>
                <Card.Body>
                  <h4 className="card-title">
                    <a href="#" className="text-fiord-blue">
                      {data.courseData.courseName}
                    </a>
                  </h4>
                  <p
                    style={{
                      wordWrap: "break-word",
                      width: "20rem",
                    }}
                  >
                    {data.courseData.courseDescription.substring(0, 100)}
                    {data.courseData.courseDescription.length > 100
                      ? "..."
                      : ""}
                  </p>
                  <p
                    style={{
                      color: "blank",
                    }}
                    className="text-muted"
                  >
                   Total Classes:  {data.classes.length}
                  </p>
                  <p
                    style={{
                      color: "blank",
                    }}
                    className="text-muted"
                  >
                  Deadline: {data.courseData.courseDeadline}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Modal
          isOpen={modal}
          onRequestClose={() => setModal(false)}
          style={customStyles}
        >
          {currentData ? (
            <div className="modal-container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  Course name
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setCourseNameNew(e.target.value)}
                  defaultValue={currentData.courseData.courseName}
                />
                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  {" "}
                  Course Description
                </p>

                <input
                  className="modal-input"
                  onChange={(e) => setCourseDescriptionNew(e.target.value)}
                  defaultValue={currentData.courseData.courseDescription}
                />

                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  {" "}
                  Course Address
                </p>

                <input
                  className="modal-input"
                  onChange={(e) => setCourseAddressNew(e.target.value)}
                  defaultValue={currentData.courseData.courseAddress}
                />

                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  {" "}
                  Course Location{" "}
                </p>

                <select
                  value={
                    courseLocationNew || currentData.courseData.courseLocation
                  }
                  onChange={(e) => setCourseLocationNew(e.target.value)}
                  className="modal-input"
                >
                  <option>Select Location</option>
                  {AllLocation.map((location) => {
                    return (
                      <option value={location.name}>{location.name}</option>
                    );
                  })}
                </select>

                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  {" "}
                  Course Tutor{" "}
                </p>

                <select
                  value={courseTutorNew || currentData.courseData.tutorId}
                  onChange={(e) => setCourseTutorNew(e.target.value)}
                  className="modal-input"
                >
                  <option>Select Tutor</option>

                  {allTutors.map((data, key) => (
                    <option value={data._id} key={key}>
                      {data.FirstName}{" "}{data.LastName}
                    </option>
                  ))}
                </select>

                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  {" "}
                  Course Status{" "}
                </p>
                <select
                  value={courseStatusNew || currentData.courseData.courseStatus}
                  onChange={(e) => setCourseStatusNew(e.target.value)}
                  className="modal-input"
                >
                  <option>Course Status</option>
                  <option value="active">Active</option>
                  <option value="deactivate">Deactivate</option>
                  <option value="terminated">Terminated</option>
                </select>
                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  {" "}
                  Course Deadline
                </p>

                <input
                  className="modal-input"
                  onChange={(e) => setDeadlineNew(e.target.value)}
                  defaultValue={currentData.courseData.courseDeadline}
                />

                <p style={{ marginTop: "10px", fontSize: "13px" }}>
                  {" "}
                  Course Price
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setCoursePriceNew(e.target.value)}
                  placeholder="Course Price"
                  defaultValue={currentData.courseData.coursePrice}
                />
              </div>

              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  marginLeft: "5rem",
                  marginBottom: "15px",
                }}
              >
                <Widget
                  onChange={(info) => {
                    setCourseImageNew(info.cdnUrl);
                  }}
                  publicKey="96f3fcac48c20a4671a9"
                  clearable
                />
              </div>
              <div className="buttonContainer">
              {currentData.classes.length==0? (
                <div
                  onClick={() => {
                    let id = currentData._id;
                    axios
                      .delete(
                        `https://kidultsports.herokuapp.com/api/app/course/delete/${id}`
                      )
                      .then((res) => {
                        window.location.reload();
                      });
                  }}
                  className="remove_button center"
                >
                  <p>Delete</p>
                  <i className={`bx bx-user-x`}></i>
                </div>
                  ) : null}
                <div
                  onClick={() => {
                    updateCourse(currentData._id);
                  }}
                  className="remove_button center"
                  style={{
                    backgroundColor: "#3895D3",
                  }}
                >
                  <p>Update</p>
                  <i className={`bx bx-user`}></i>
                </div>
              </div>
            </div>
          ) : null}
        </Modal>
      </Container>
    </>
  );
}
