import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Table from "../components/table/Table";
import axios from "axios";
import Modal from "react-modal";
import moment from "moment";
import "../assets/css/AllStudent.css";
import { OutlinedInput } from "@material-ui/core";
import { Widget } from "@uploadcare/react-widget";
import { ScrollView } from '@react-ui-org/react-ui';


const Customers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [studentModal, setStudentModal] = useState(false);
  const [userData, setuserData] = useState({});
  const [studentloading, setStudentLoading] = useState(true);
    const [profilepic, setProfilePic] = useState("");
  // Update User State
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [dateofbirth, setDateofbirth] = useState("");
  const [tutorLocation, setLocation] = useState("");
  const [tutorEmail, setTutorEmail] = useState("");
  const [tutorAddress, setTutorAddress] = useState("");
  const [tutorPhone, setTutorPhone] = useState("");
  const [tutorBank, setTutorBank] = useState("");
  const [tutorBudget, setTutorBudget] = useState("");
  const [hkidNumber, setHKIDNumber] = useState("");
  const [tutorpassword, setPassword] = useState("");
  const [AllLocation, setAllLocations] = useState([]);
  const [courseLocationNew, setCourseLocationNew] = useState("");
  

  let theme = localStorage.getItem("themeMode");

  const customerTableHead = [
    "First Name",
    "Last Name",
    "Email Address",
    "Phone Number",
    "Address",
    "Bank Account",
    "Budget",
    "Create/Update Date",
  ];

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "25%",
      bottom: "auto",
      backgroundColor: "#FAFAFB",
      border: "none",
      marginRight: "-50%",
      height: "80%",
      overflowX: "hidden",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;
  const deleteAccount = (userId) => {
    axios
      .delete(`https://kidultsports.herokuapp.com/api/auth/delete/${userId}`)
      .then(() => {
        toast.info("User deleted successfully", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };
  const getAllLocations = () => {
    axios
      .get("https://kidultsports.herokuapp.com/api/app/locations/all")
      .then((res) => {
        setAllLocations(res.data);
      });
  };
  const updateUser = (userId) => {
    axios
      .get(`https://kidultsports.herokuapp.com/api/auth/user/${userId}`)
      .then((e) => {
        let tutorData = e.data;
        axios
          .put(
            `https://kidultsports.herokuapp.com/api/auth/update/${userId}`,
            {
              FirstName: firstName || tutorData.FirstName,
              LastName: lastName || tutorData.LastName,
              Email: tutorEmail || tutorData.Email,
              Phone: tutorPhone || tutorData.Phone,
              StreetAddressLine2: tutorAddress || tutorData.StreetAddressLine2,
              DateOfBirth: dateofbirth || tutorData.DateOfBirth,
              Password: tutorpassword || tutorData.Password,
              role: "tutor",
              profile: profilepic || tutorData.profile,
              userData: {             
                bankAccount: tutorBank || tutorData.userData.bankAccount,
                HKIDNumber: hkidNumber || tutorData.userData.HKIDNumber,               
                budget: tutorBudget || tutorData.userData.budget,             
                location: tutorLocation || tutorData.userData.location,             
              },
            }
          )
          .then((updatedData) => {
            setStudentModal(false);
            toast.info("Tutor updated successfully", {
              position: "bottom-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          });
      });
  };

  const renderBody = (item, index) => {

    return (
      <tr
        onClick={() => {
          setuserData(item);
          setStudentLoading(false);
          setStudentModal(true);
        }}
        style={{ cursor: "pointer" }}
        key={index}
      >
        <>
          <td>{item.FirstName}</td>
          <td>{item.LastName}</td>
          <td>{item.Email}</td>
          <td>{item.Phone}</td>          
          <td>{item.StreetAddressLine2 || "NA"}</td>
          <td>{item.userData.bankAccount || "NA"}</td>
          <td>{item.userData.budget || "NA"}</td>
          <td>{moment(item.updatedAt).format('YYYY-MM-DD') || "NA"}</td>
        </>
      </tr>
    );
  };

  useEffect(() => {
    axios.get("https://kidultsports.herokuapp.com/api/auth/users").then((e) => {
      let AllUsers = e.data;
      let tutor = [];
      AllUsers.map((e) => {
        if (e.role == "tutor") {
          tutor.push(e);
        }
      });
      if (tutor.length > 0) {
        let sortedBasedOnName = tutor.sort((a, b) => a.FirstName > b.FirstName ? 1 : -1);
        setAllUsers(sortedBasedOnName);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <h2 className="page-header"></h2>
      <div className="row">
        <div className="col-12">
          {!loading ? (
            <div className="card">
              <div className="card__body">
                <Table
                  limit="20"
                  headData={customerTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={allUsers}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              </div>
            </div>
          ) : null}
        </div>
        {!studentloading ? (
          <Modal
            isOpen={studentModal}
            onRequestClose={() => setStudentModal(false)}
            style={customStyles}
            
          >
          
            <div className="modal-container">
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
            <img
                src={
                  userData.userData.profile
                    ? userData.userData.profile
                    : `https://eu.ui-avatars.com/api/?background=9CD5D5&color=fff&size=512&name=${userData.userData.profile}`
                }
                className="modal-img"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                 <p
                  style={{
                    marginTop: "5px",
                    fontSize: "11px",
                  }}
                >
                  First Name
                </p>
                <input
                  placeholder="First Name"
                  className="modal-input"
                  onChange={(e) => setfirstName(e.target.value)}
                  defaultValue={userData.FirstName}
                />
                    <p
                  style={{
                    marginTop: "5px",
                    fontSize: "11px",
                  }}
                >
                  Last Name
                </p>
                <input
                  placeholder="Last Name"
                  className="modal-input"
                  onChange={(e) => setlastName(e.target.value)}
                  defaultValue={userData.LastName}
                />
                <p
                  style={{
                    marginTop: "5px",
                    fontSize: "11px",
                  }}
                >
                  Email
                </p>
                <input
                  placeholder="Email Address"
                  className="modal-input"
                  onChange={(e) => setTutorEmail(e.target.value)}
                  defaultValue={userData.Email}
                />
                  <p
                style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
                >
                  Date Of Birth
                </p>

                <input
                  type="date"
                  onChange={(e) => {
                    setDateofbirth(e.target.value);
                  }}
                  defaultValue={userData.DateOfBirth}
                  placeholder="Date of Birth"
                  className="modal-input"
                  />
                <p
               style={{
                marginTop: "5px",
                fontSize: "11px",
              }}
                >
                  Phone Number
                </p>
                <input
                  placeholder="Phone Number"
                  className="modal-input"
                  onChange={(e) => setTutorPhone(e.target.value)}
                  defaultValue={userData.Phone}
                />
              
                <p
                style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
                >
                  Location
                </p>
                <input
                  onChange={(e) => {
                  setLocation(e.target.value); }}
                  defaultValue={userData.userData.location}
                  placeholder="Location"
                  className="modal-input"
                />

                <p
                style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
                >
                  Address
                </p>
                <input
                  placeholder="Address"
                  className="modal-input"
                  onChange={(e) => setTutorAddress(e.target.value)}
                  defaultValue={userData.StreetAddressLine2}
                />
                 <p
                style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
                >
                  Bank Account
                </p>
                <input
                  placeholder="Bank Account"
                  className="modal-input"
                  onChange={(e) => setTutorBank(e.target.value)}
                  defaultValue={userData.userData.bankAccount}
                />
                 <p
                style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
                >
                  Budget
                </p>
                <input
                  placeholder="Budget"
                  className="modal-input"
                  onChange={(e) => setTutorBudget(e.target.value)}
                  defaultValue={userData.userData.budget}
                />
                 <p
                style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
                >
                  HKID
                </p>
                <input
                  placeholder="HKID Number"
                  className="modal-input"
                  onChange={(e) => setHKIDNumber(e.target.value)}
                  defaultValue={userData.userData.HKIDNumber}
                />
                <p
                 style={{
                  marginTop: "5px",
                  fontSize: "11px",
                }}
                >
                  User Password
                </p>
                <input
                  className="modal-input"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={"Password"}
                />
                 </div>
                 <Widget
                  onChange={(info) => {
                    setProfilePic(info.cdnUrl);
                  }}
                  publicKey="96f3fcac48c20a4671a9"
                  clearable
                />
              </div>
              <br />
              <div className="buttonContainer">
                <div
                  onClick={() => deleteAccount(userData._id)}
                  className="remove_button center"
                >
                  <p>Delete User</p>
                  <i className={`bx bx-user-x`}></i>
                </div>
                <div
                  onClick={() => updateUser(userData._id)}
                  className="remove_button center"
                  style={{
                    backgroundColor: "#3895D3",
                  }}
                >
                  <p>Update Tutor</p>
                  <i className={`bx bx-user`}></i>
                </div>
              </div>
            </div>
          
          </Modal>
        ) : null}
      </div>
    </div>
  );
};

export default Customers;
