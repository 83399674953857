import React, { useState, useEffect } from "react";
import Input from "../components/Input/Input";
import axios from "axios";
import { toast } from "react-toastify";
import NewTable from "../components/NewTable/index.js";
import renderBody from "./renderBody";
import moment from "moment";
export default function EnrollStudent() {
  const [courseId, setCourseId] = useState("");
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [classes, setClasses] = useState([]);
  const [FPSId, setFPSId] = useState("");

  const [students, setStudents] = useState([]);
  const [studentId, setStudentId] = useState("");
  const [allCourses, setAllCourses] = useState([]);
  const [classesIndex, setClassesIndex] = useState([]);
  const [enrolledStudents, setEnrollStudents] = useState([]);
  const [payment, setPayment] = useState("");

  const getClasses = async (id) => {
    setCourseId(id);
    if (id.length > 15) {
      const response = await fetch(
        `https://kidultsports.herokuapp.com/api/app/course/${id}`
      );
      const data = await response.json();
      setClasses(data.classes);
      setShowCheckBox(true);
    }
  };

  const handleSubmit = async () => {
  
    if(!studentId || !courseId){
      console.log("Student and Course are required for enrollment!")
      toast.warning("Student and Course are required for enrollment!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }else{
      let random6Number = Math.floor(Math.random() * 1000000);

      let postData = {
        studentId: studentId,
        courseId: courseId,
        invoiceNumber: random6Number,
        invoiceDate: Date.now(),
      };

      let newInvoice = await axios.post(
        "https://kidultsports.herokuapp.com/api/app/invoice/create",
        postData
      )
      //Modify by kelly
      if(newInvoice){

        let courseInfo = await axios.get(`https://kidultsports.herokuapp.com/api/app/course/${courseId}`)
        if(courseInfo){
          courseInfo.data.classes.map(async (classId) => {
            axios.post(
               "https://kidultsports.herokuapp.com/api/app/class/enroll/" +
               classId,
               {
                 student: {
                   date: moment().format("YYYY-MM-DD hh:mm"),
                   userId: studentId,
                   paymentMethod: payment,
                   FPSID: FPSId,
                   invoice: newInvoice.data.details._id,
                   checkedIn: false,
                 },
               }
             )
           })

         // courseInfo.data.classes.map(async (courseId) => {
            let newEnrollment = await axios.post(
              "https://kidultsports.herokuapp.com/api/app/course/enroll/" +
              courseId,
              {
                student: {
                  date: moment().format("YYYY-MM-DD hh:mm"),
                  userId: studentId,
                  paymentMethod: payment,
                  FPSID: FPSId,
                  invoice: newInvoice.data.details._id,
                  checkedIn: false,
                },
              }
            )

            if(newEnrollment){
              let tutorId = courseInfo.data.courseData.tutorId;
              let courseName = courseInfo.data.courseData.courseName;
              sendNotifications(studentId, tutorId, courseName);

              toast.info("Student Enrolled Successfully", {
                position: "bottom-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }

        }
      }
    }
  };

  

  const sendNotifications = async (studentId, tutorId, courseName) => {
    let AllTutorNotifications = [];
    axios
      .get(`https://kidultsports.herokuapp.com/api/auth/user/${tutorId}`)
      .then((res) => {
        res.data.notifications.map((notification) => {
          AllTutorNotifications.push(notification);
        });
        AllTutorNotifications.push({
          from: studentId,
          type: "enrollment",
          courseName: courseName,
        });
        axios
          .put(
            `https://kidultsports.herokuapp.com/api/app/notification/${tutorId}`,
            {
              notifications: AllTutorNotifications,
            }
          )
          .then((res) => {
            console.log(res.data);
          });
      });
  };

  useEffect(async () => {
    let allStudentsTempArray = [];
    let e = await axios.get("https://kidultsports.herokuapp.com/api/auth/users")
    if(e.data.length > 0){
      let sortedBasedOnName = e.data.sort((a, b) => a.FirstName > b.FirstName ? 1 : -1);
      for (let i = 0; i < sortedBasedOnName.length; i++) {
        if (
          sortedBasedOnName[i].role !== "tutor" &&
          sortedBasedOnName[i].role !== "admin" &&
          sortedBasedOnName[i].role !== "parent"
        ) {
          allStudentsTempArray.push(sortedBasedOnName[i]);
        }
      }
     
      setStudents(allStudentsTempArray);
      await getAllEnrolledStudents(allStudentsTempArray);
    }

     let coursesData = await axios.get("https://kidultsports.herokuapp.com/api/app/courses")
     if(coursesData.data.length > 0){
       setAllCourses(coursesData.data);
     }
  
  }, []);

  const getAllEnrolledStudents = async (allStudentsTempArray) => {
    let allEnrolledStudents = [];
    let courseData = await axios.get("https://kidultsports.herokuapp.com/api/app/courses")
    if(courseData.data.length > 0){
      let AllCourse = courseData.data;
      AllCourse.map((e) => {
          e.students.map((student) => {
            let studentId = student.userId;       
            let studentInfo = allStudentsTempArray.filter((obj)=>obj._id == studentId)[0];
            let courseData = e.courseData;
            let studentData = {
              studentId: student.userId,
              studentFirst: student.FirstName,
              studentInfo: studentInfo,
             courseData: courseData,
              paymentMethod: student.paymentMethod,
              FPSID: student.FPSID,
              payed: student.payed,
              Ids: {
                courseId: e._id,
                courseName: e.courseName,
                studentId: student.userId,
                invoiceId: student.invoice,
              },
            };
            allEnrolledStudents.push(studentData);
          });
        });

        //Ordering Enrolled Students
        let sortedBasedOnName = allEnrolledStudents.sort((a, b) => a.studentFirst > b.studentFirst ? 1 : -1);
        setEnrollStudents(sortedBasedOnName);
    }
  };

  const renderHead = (item, index) => <th key={index}>{item}</th>;

  return (
    <div>
      <h2 className="page-header">Enroll Students (This is a course enroll data with overall payment, not class enroll)</h2>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <select
          value={studentId}
          onChange={(e) => setStudentId(e.target.value)}
          className="form-control"
        >
          <option>Select Student *</option>

          {students.map((data, key) => (
            <option value={data._id} key={key}>
             {data.FirstName} {data.LastName} 
            </option>
          ))}
        </select>

        <select
          onChange={(e) => getClasses(e.target.value)}
          className="form-control"
        >
          <option>Select Course *</option>

          {allCourses.map((data, key) => (
            <option value={data._id} key={key}>
              {data.courseData.courseName}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => setPayment(e.target.value)}
          className="form-control"
        >
          <option>Select Payment Method *</option>
          <option value="cash">Cash</option>
          <option value="stripe">Stripe</option>
          <option value="fps">FPS</option>
        </select>
        {payment == "fps" ? (
          <Input
            value={FPSId}
            onChange={(e) => {
              setFPSId(e.target.value);
            }}
            placeholder={"FPS ID"}
            icon="money"
          />
        ) : null}


        <div onClick={handleSubmit} className="add_button" style={{marginBottom: "15px", marginTop: "-5px"}}>
          <p>Add Student</p>
          <i className={`bx bx-user`}></i>
        </div>
      </div>

      <div className="card">
        <div className="card__body">
          {enrolledStudents.length > 0 && (
            <NewTable data={enrolledStudents} rowsPerPage={2000}/>
          )}
        </div>
      </div>
    </div>
  );
}
